import {
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fragment, useState } from "react";
import { useMutation } from "@apollo/client";
import { deshabilitaPrestacion } from "../../../../services/administracionCM/administracionCMServices";
import { checkStatus } from "../../utils/checkStatus";
import { ModalPrestacion } from "./modalNuevaPrestacion";

export const MenuAccionesPrestaciones = ({ row, prestacion, isBonif, refetch, muestraMensaje, disabled, objModalPrestacion }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModificarPrestacion, seModificarPrestacion] = useState<boolean>(false);
    const openMenu = Boolean(anchorEl);

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleModificarPrestacion = () => {
        seModificarPrestacion(true);
        setAnchorEl(null);

    }
    const [eliminarPrest, { loading }] = useMutation(deshabilitaPrestacion, {
        variables: {
            idPrestacion: [prestacion.id],
            type: isBonif ? 2 : 1
        },
        onCompleted: () => {
            muestraMensaje("Prestación(es) eliminada(s) con éxito.", 'success');
            refetch();
        },
        onError: (err: any) => {
            console.log(err.message)
            muestraMensaje("Ocurrió un error al eliminar la(s) prestación(es). Por favor, inténtalo de nuevo.", 'error');
            checkStatus(err.graphQLErrors[0]?.code || "");
        }
    });
    const handleEliminarPrestacion = () => {
        eliminarPrest();
        setAnchorEl(null);
    }
    return (
        <Fragment>
            <ModalPrestacion
                onOpen={openModificarPrestacion}
                onClose={() => seModificarPrestacion(false)}
                prestacion={prestacion}
                refetch={refetch}
                muestraMensaje={muestraMensaje}
                idCuentaMedica={objModalPrestacion.idCuentaMedica}
                fechaHospitalizacion={objModalPrestacion.fechaHospitalizacion}
                idCobro={objModalPrestacion.idCobro}
                idCobertura={objModalPrestacion.idCobertura}
                isGES={objModalPrestacion.isGES}
                totalDoc={objModalPrestacion.totalDoc}
                totalPres={objModalPrestacion.totalPres}
            />
            <IconButton
                data-testid="openMenuItem"
                id="basic-button"
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreVertIcon sx={{ color: "#203442" }} />
            </IconButton>
            <Menu
                id={`menu-${prestacion.id}`}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                className="menuForm"
            >
                {!isBonif && <MenuItem
                    className="menuForm"
                    onClick={handleModificarPrestacion}
                    disabled={disabled || loading}
                >
                    Editar
                </MenuItem>}
                <MenuItem
                    className="menuForm"
                    onClick={handleEliminarPrestacion}
                    disabled={disabled || loading}

                >
                    Eliminar
                </MenuItem>
            </Menu>
        </Fragment>
    )
}