import { Checkbox, CircularProgress, InputAdornment, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { CustomSelectInput, menuFirstStyle, menuItemStyle2, menuSelectStyle } from "../../assets/styles/Select/Select";
import { verificaLargo } from "../Tooltip/Tooltip";

type selectMultipleProps = {
    onChange: (event: SelectChangeEvent<string | string[]>) => void;
    values: string[];
    selectedValues: string | string[];
    placeholder: string;
    disabled?: boolean
    loading?: boolean
}

const sliceText = (text: string) => {
    if (text.length > 32) {
        return text.slice(0, 32).trim() + "...";
    } else {
        return text
    }
}

export const SelectMultiple = ({ onChange, values, selectedValues, placeholder, disabled, loading }: selectMultipleProps) => {

    const renderText = (array: string | string[]) => {
        if (array.length === 0) {
            return <Typography sx={menuFirstStyle}>{placeholder}</Typography>;
        }
        if (typeof array === 'string') return sliceText(`(N° 1) ${array}`)

        const res = array.map((str: string, inx: number) => sliceText(`(N° ${inx + 1}) ${str}`));
        return res.join(', ')
    }

    const menuItemText = (item: string) => {
        const index = selectedValues.indexOf(item);
        if (index > -1) {
            return verificaLargo(`(N° ${index + 1}) ${item}`, 80, 'bottom')
        } else {
            return verificaLargo(item, 80, 'bottom')
        }
    }

    return (
        <Select
            multiple
            displayEmpty
            disabled={disabled || loading}
            value={selectedValues || []}
            onChange={onChange}
            input={<CustomSelectInput />}
            renderValue={(selected) => renderText(selected)}
            endAdornment={
                <InputAdornment
                    position="end"
                    sx={{ pointerEvents: "none", position: "absolute", right: 35 }}>
                    {loading && <CircularProgress size={20} />}
                </InputAdornment>
            }
            MenuProps={menuSelectStyle}
        >
            <MenuItem disabled value="">
                <Typography sx={menuFirstStyle}>{placeholder}</Typography>
            </MenuItem>
            {values.map((item) => (
                <MenuItem key={item} value={item} sx={menuItemStyle2}>
                    <Checkbox checked={selectedValues.indexOf(item) > -1} />
                    <ListItemText primary={menuItemText(item)} />
                </MenuItem>
            ))}
            {values.length === 0 && <MenuItem key={"estadoCero"} value={""} sx={menuItemStyle2} disabled>
                <em>(Sin ítems seleccionables)</em>
            </MenuItem>}
        </Select>
    );
}