export const reloaderTableValo = () => {
    const tableRefetch = document.getElementsByName("btnRefetchCobro");
    if (tableRefetch.length > 0) {
        tableRefetch.forEach((btn) => {
            btn.click();
        })
    }
}

export const reloaderCuenta = () => {
    const refetchCTA = document.getElementById("btnRefetchCuenta");
    if (refetchCTA) {
        refetchCTA.click();
    }
}