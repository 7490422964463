import { useState } from "react";
import {
    Box,
    Collapse,
    FormControl,
    Grid,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import IconData from "../../../../assets/icons/addDatesIcon.svg";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { formTextHeader, inputForm } from "../../../../assets/styles/Text/text";
import { CoberturaSpan } from "./coberturaSpan";
import { SelectMultiple } from "../../../../components/Selects/SelectMultiple";
import { TransitionGroup } from "react-transition-group";
import { EmptyStateMessage } from "../../../../components/message/emptyStateMessage";
import { Stack } from "@mui/system";
import Aviso from "../../../../components/Alert/Aviso";

export const tipoCoberturas = ["GES", "CAEC", "PLAN"];

export type prioridadCoberturaType = {
    id?: number,
    idCuenta: number,
    cobertura: number,
    casoGES: number | null,
    casoCAEC: number | null,
    problemaSalud: number | null,
    CIE10: string | null,
    canasta: string | null,
    idBeneficios: number | null,
    prioridad: number
}

export type dataCobertura = { GES: number[], CAEC: number[], PLAN: number[] }

const DataCobertura = ({ dataCM, setDataCM, readOnly }: any) => {
    const [cobertureValues, setCobertureValues] = useState<string | string[]>(dataCM.datosCoberturas.cobertureValues || []);
    const disabled = !Boolean(dataCM.fechaHospitalizacion.fechaInicio);

    const handleChange = (event: SelectChangeEvent<typeof cobertureValues>) => {
        let casos = dataCM.datosCoberturas.prioridadCoberturas;
        let prioridades: prioridadCoberturaType[] = [...dataCM.dataPrioridad];
        const {
            target: { value },
        } = event;

        if (!value.includes("GES")) {
            casos.GES = [];
            prioridades = prioridades.filter(item => item.cobertura === 1);
        }
        if (!value.includes("CAEC")) {
            casos.CAEC = [];
            prioridades = prioridades.filter(item => item.cobertura === 2);
        }
        if (!value.includes("PLAN")) {
            casos.PLAN = [];
            prioridades = prioridades.filter(item => item.cobertura === 3);
        }
        if (value.includes("PLAN")) {
            if (!prioridades.find((item) => item.cobertura === 3)) {
                prioridades.push({
                    idCuenta: dataCM.idCuentaMedica,
                    cobertura: 3,
                    casoGES: null,
                    casoCAEC: null,
                    problemaSalud: null,
                    CIE10: null,
                    canasta: null,
                    idBeneficios: null,
                    prioridad: 1
                })
            }
        }
        setDataCM({ ...dataCM, datosCoberturas: { cobertureValues: value, prioridadCoberturas: casos }, dataPrioridad: prioridades });
        setCobertureValues(value);
    };

    return (
        <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
            <Box sx={formHeader}>
                <Typography sx={formTextHeader}>
                    <img src={IconData} alt="" style={{ marginBottom: "-3px" }} /> {' '} Cobertura Esencial
                </Typography>
            </Box>
            <Aviso open={disabled} severidad={'warning'} titulo="Debes completar los campos de fecha de hospitalización para visualizar coberturas" />
            <Grid mb="32px" container p="24px">
                <Grid item xs={12} pr="24px">
                    <Stack>
                        <Typography sx={inputForm}>
                            <Typography sx={formTextHeader} display="inline">
                                N°{" "}
                            </Typography>
                            indicara el
                            <Typography sx={formTextHeader} display="inline">
                                {" "}orden de la selección{" "}
                            </Typography>
                            de prioridad de la cobertura, para eliminar algún campo deselecciona la opción que no corresponda.
                        </Typography>
                    </Stack>

                    <Typography sx={inputForm}  >
                        Tipo cobertura
                    </Typography>
                    <Typography display={'inline'} color={'#E53935 !important'}>
                        *
                    </Typography> <br></br>
                    <FormControl disabled={!dataCM.vigenciaPoliza || readOnly} sx={{ width: 400 }}>
                        <SelectMultiple
                            disabled={disabled || readOnly}
                            selectedValues={cobertureValues}
                            values={tipoCoberturas}
                            onChange={handleChange}
                            placeholder="Seleccione por orden de prioridad"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} pr="24px">
                    <TransitionGroup>
                        {cobertureValues.length > 0
                            ? typeof cobertureValues === 'string' ?
                                <Collapse key={cobertureValues}>
                                    <CoberturaSpan cobType={cobertureValues} cobertureText={cobertureValues} dataCM={dataCM} setDataCM={setDataCM} disabled={disabled || readOnly} />
                                </Collapse> :
                                cobertureValues.map(
                                    (cob, inx) =>
                                        <Collapse key={cob}>
                                            <CoberturaSpan cobType={cob} cobertureText={`(N° ${inx + 1}) - ${cob}`} dataCM={dataCM} setDataCM={setDataCM} disabled={disabled || readOnly} />
                                        </Collapse>)
                            : null}
                    </TransitionGroup>
                    <Collapse in={cobertureValues.length === 0}>
                        <EmptyStateMessage
                            title="No hay información para mostrar."
                            subtitle="Seleccione información de cobertura"
                        />
                    </Collapse>
                </Grid>
            </Grid>
        </Box>
    );


}

export default DataCobertura;