export const initialState = {
    datosPrestador: {
        rutPrestador: null,
        numeroCuenta: null,
        numeroCobro: null,
        razonSocial: null,
        rutMedicoTratante: null,
        nombreMedicoTratante: null
    },
    datosBeneficiario: {
        rut: null,
        nombre: null,
        diagnosticoMedico: null,
        diagnosticoPrimario: null,
        diagnosticoSecundario: null
    },
    datosCoberturas: {
        prioridadCoberturas: { GES: [], CAEC: [], PLAN: [] },
        cobertureValues: []
    },
    fechaRecepcion: null,
    fechaProbableLiquidacion: null,
    prestacionPrincipal: null,
    estadoCuentaMedica: {
        estado: null,
        nombre: null,
        causal: null,
        descripcionCausal: null
    },
    fechaHospitalizacion: {
        fechaInicio: null,
        fechaEgreso: null,
        horaInicio: null,
        horaEgreso: null,
        condicionEgreso: null
    },
    fechaCobro: {
        fechaInicio: null,
        fechaTermino: null
    },
    cantidadIntervenciones: null,
    graduacionParto: null,
    montoCobro: null,
    tipoAtencion: null,
    leyUrgencia: null,
    cuentaComplementaria: null,
    soap: null,
    cuentaPaquetizada: null,
    tipoCobertura: {
        active: null,
        items: null
    },
    tipoHabitacion: {
        active: null,
        items: null
    },
    tipoRegimen: {
        active: null,
        items: null
    },
    canalOrigen: null,
    idCuentaMedica: null,
    idEventoHospitalario: null,
    tipoCuentaMedica: null,
    poliza: {
        id: null,
        numero: null,
        version: null,
        fechaInicioVigencia: null,
        fechaTerminoVigencia: null,
        codigoPlanSalud: null,
        glosaPlanSalud: null
    },
    vigenciaPoliza: false
}