import { gql } from "@apollo/client";

export const mutationAgregarDocCobro = gql`
mutation mutationAgregarDocCobro(
    $idCuentaMedica: Int
    $tipoDocumento: Int
    $folioDocumento: Int
    $fechaDocumento: Date4
    $rutPrestador: String
    $convenioPrestador: Boolean
    $razonSocial: String
    $codigoLugarAtencion: Int
    $codigoLugarAtencionIsapre: Int
    $lugarAtencion: String
    $codigoPaquete: String
    $descPaquete: String
    $monto: Int
      ){
        crearDocumentoCobro(idCuentaMedica:$idCuentaMedica, documentoCobro: {
            tipoDocumento:  $tipoDocumento
            folioDocumento: $folioDocumento
            fechaDocumento: $fechaDocumento
            rutPrestador: $rutPrestador
            convenioPrestador: $convenioPrestador
            razonSocial: $razonSocial
            codigoLugarAtencion: $codigoLugarAtencion
            codigoLugarAtencionIsapre: $codigoLugarAtencionIsapre
            lugarAtencion: $lugarAtencion
            codigoPaquete: $codigoPaquete
            descPaquete: $descPaquete
            monto: $monto
        }){
            respuesta{
            estado
            mensaje
            }
        }
}`;

export const mutationEliminarDocCobro = gql`
mutation mutationEliminarDocCobro($id: Int){
    eliminarDocumentoCobro(id : $id) {
        estado
        mensaje  
    }
}`;

export const mutationEditarDocCobro = gql`
mutation mutationEditarDocCobro(
    $idCuentaMedica: Int
    $id: Int
    $tipoDocumento: Int
    $folioDocumento: Int
    $fechaDocumento: Date4
    $rutPrestador: String
    $convenioPrestador: Boolean
    $razonSocial: String
    $codigoLugarAtencion: Int
    $codigoLugarAtencionIsapre: Int
    $lugarAtencion: String
    $codigoPaquete: String
    $descPaquete: String
    $monto: Int
      ){
        editarDocumentoCobro(idCuentaMedica:$idCuentaMedica, id: $id,documentoCobro: {
            tipoDocumento:  $tipoDocumento
            folioDocumento: $folioDocumento
            fechaDocumento: $fechaDocumento
            rutPrestador: $rutPrestador
            convenioPrestador: $convenioPrestador
            razonSocial: $razonSocial
            codigoLugarAtencion: $codigoLugarAtencion
            codigoLugarAtencionIsapre: $codigoLugarAtencionIsapre
            lugarAtencion: $lugarAtencion
            codigoPaquete: $codigoPaquete
            descPaquete: $descPaquete
            monto: $monto
        }){
            estado
            mensaje
        }
}`


export const queryDatosPrestador = gql`
query queryDatosPrestador($rut: String){
  datosPrestador(rut: $rut){

      lugarAtencion{
        codigo
        descripcion
      }
      paquete{
        codigo
        descripcion
      }
      tieneConvenio
      razonSocial

  }
}`

export const queryTodosPrestadores = gql`
query{
  todosPrestadores{
     internalRolId
     rut
     razonSocial
     nombreCalle
     numeroCalle
     region
     ciudad
     comuna
     vigenciaFinal
     vigenciaInicial
     luat
  }
 }`

export const mutationCrearDocumentoCobroCobertura = gql`
mutation mutationCrearDocumentoCobroCobertura(
  $idCuentaMedica: Int
  $tipoDocumento: Int
  $tipoCobertura: Int
  $fechaDocumento: Date
  $folioDocumento: Int
  $folioCobertura: Int
  $convenioPrestador: Boolean
  $razonSocial: String
  $rutPrestador: String
  $codigoLugarAtencion: Int
  $codigoLugarAtencionIsapre: Int
  $lugarAtencion: String
  $codigoPaquete: Int
  $descPaquete: String
  $monto: Int
){
  crearDocumentoCobroCobertura(idCuentaMedica:$idCuentaMedica, documentoCobroCobertura: {
    tipoDocumento: $tipoDocumento
    tipoCobertura: $tipoCobertura
    fechaDocumento: $fechaDocumento
    folioDocumento: $folioDocumento
    folioCobertura: $folioCobertura
    convenioPrestador: $convenioPrestador
    razonSocial: $razonSocial
    rutPrestador: $rutPrestador
    codigoLugarAtencion: $codigoLugarAtencion
    codigoLugarAtencionIsapre: $codigoLugarAtencionIsapre
    lugarAtencion: $lugarAtencion
    codigoPaquete: $codigoPaquete
    descPaquete: $descPaquete
    monto: $monto
  }) {
    estado
    codigo
    mensaje
  }
}`;

export const mutationEditarDocumentoCobroCobertura = gql`
mutation mutationEditarDocumentoCobroCobertura(
  $id: Int
  $idCuentaMedica: Int
  $tipoDocumento: Int
  $tipoCobertura: Int
  $fechaDocumento: Date
  $folioDocumento: Int
  $folioCobertura: Int
  $convenioPrestador: Boolean
  $razonSocial: String
  $rutPrestador: String
  $codigoLugarAtencion: Int
  $codigoLugarAtencionIsapre: Int
  $lugarAtencion: String
  $codigoPaquete: Int
  $descPaquete: String
  $monto: Int
){
  editarDocumentoCobroCobertura(id: $id, idCuentaMedica: $idCuentaMedica, documentoCobroCobertura: {
    tipoDocumento: $tipoDocumento
    tipoCobertura: $tipoCobertura
    fechaDocumento: $fechaDocumento
    folioDocumento: $folioDocumento
    folioCobertura: $folioCobertura
    convenioPrestador: $convenioPrestador
    razonSocial: $razonSocial
    rutPrestador: $rutPrestador
    codigoLugarAtencion: $codigoLugarAtencion
    codigoLugarAtencionIsapre: $codigoLugarAtencionIsapre
    lugarAtencion: $lugarAtencion
    codigoPaquete: $codigoPaquete
    descPaquete: $descPaquete
    monto: $monto
  }) {
    estado
    codigo
    mensaje
  }
}`;

export const mutationEliminarDocumentoCobroCobertura = gql`
mutation mutationEliminarDocumentoCobroCobertura(
  $id: Int
  $idCob: Int
  $idCuentaMedica: Int
){
  eliminarDocumentoCobroCobertura(
    id: $id
    idCob: $idCob
    idCuentaMedica: $idCuentaMedica
  ) {
    estado
    mensaje  
  }
}`;

export const mutationDuplicarDocumentoCobroCobertura = gql`
mutation mutationDuplicarDocumentoCobroCobertura(
  $idCuentaMedica: Int
  $tipoDocumento: Int
  $tipoCobertura: Int
  $fechaDocumento: Date
  $folioDocumento: Int
  $folioCobertura: Int
  $convenioPrestador: Boolean
  $razonSocial: String
  $rutPrestador: String
  $codigoLugarAtencion: Int
  $codigoLugarAtencionIsapre: Int
  $lugarAtencion: String
  $codigoPaquete: Int
  $descPaquete: String
  $monto: Int
  $id: Int
){
  duplicarDocumentoCobroCobertura(idCuentaMedica:$idCuentaMedica, documentoCobroCobertura: {
    tipoDocumento: $tipoDocumento
    tipoCobertura: $tipoCobertura
    fechaDocumento: $fechaDocumento
    folioDocumento: $folioDocumento
    folioCobertura: $folioCobertura
    convenioPrestador: $convenioPrestador
    razonSocial: $razonSocial
    rutPrestador: $rutPrestador
    codigoLugarAtencion: $codigoLugarAtencion
    codigoLugarAtencionIsapre: $codigoLugarAtencionIsapre
    lugarAtencion: $lugarAtencion
    codigoPaquete: $codigoPaquete
    descPaquete: $descPaquete
    monto: $monto
    id: $id
  }) {
    estado
    codigo
    mensaje
  }
}`;