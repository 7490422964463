import { Box, Grid, Stack, Typography, FormControl, SelectChangeEvent, Collapse } from "@mui/material"
import { textAccordion, titleAccordion } from "../../../../assets/styles/Text/text"
import { formHeader } from "../../../../assets/styles/Box/boxs"
import { dataCobertura, prioridadCoberturaType } from "./dataCoberturaEsencial"
import { CasosSelector } from "./casosSelector"
import { useQuery } from "@apollo/client"
import { obtenerCasosGESCAECByRutFecha } from "../../../../services/administracionCM/administracionCMServices"
import { format } from "date-fns"
import { useState } from "react"
import { SelectMultiple } from "../../../../components/Selects/SelectMultiple"
import { TableDataCoberture } from "./TableData"
import { TransitionGroup } from "react-transition-group"

type cobertureSpanProps = {
    cobType: string
    cobertureText: string
    dataCM: any
    setDataCM: Function
    disabled?: boolean
}
export type ObtenerCasosGESCAEC = {
    folio: string
    rut: string
    problemaSalud: string
    diagnosticoCaec: string
    diagnosticoCie10: string
    glosaCie10: string
    glosaProblemaSalud: string
    tipoCaso: string
    texto?: string
    canastas: [Canasta]
}

export type Canasta = {
    id: number
    canasta: string
    idBeneficios: number
    descripcioncanasta: string
}

const buildText = (item: ObtenerCasosGESCAEC, type: string) => {
    if (type === 'GES') {
        return `Folio ${item.folio} - PS ${item.problemaSalud} ${item.glosaProblemaSalud}`;
    } else {
        return `Folio ${item.folio} - CIE10 ${item.diagnosticoCie10} ${item.glosaCie10}`;
    }
}

export const CoberturaSpan = ({ cobType, cobertureText, dataCM, setDataCM, disabled }: cobertureSpanProps) => {
    const [datacaso, setDataCaso] = useState<ObtenerCasosGESCAEC[]>([]);
    const [selectCasos, setselectCasos] = useState<string | string[]>([]);
    const [casosList, setCasosList] = useState<string[]>([]);

    const cobertureType = cobType as keyof dataCobertura;

    const { loading } = useQuery(obtenerCasosGESCAECByRutFecha, {
        fetchPolicy: "cache-first",
        variables: {
            rut: dataCM.datosBeneficiario.rut,
            fechaHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio, 'yyyy-MM-dd')
        },
        onCompleted: (res: { obtenerCasosGESCAECByRutFecha: ObtenerCasosGESCAEC[] }) => {
            let casosSelected: string[] = [];
            let casosText: string[] = [];
            let casos = res.obtenerCasosGESCAECByRutFecha.filter((caso) => caso.tipoCaso === cobType);
            casos = casos.map(caso => {
                let texto = buildText(caso, cobertureType);
                casosText.push(texto);
                return { ...caso, texto }
            });
            dataCM.datosCoberturas.prioridadCoberturas[cobertureType].forEach((folio: number) => {
                casosSelected.push(casos.find(caso => Number(caso.folio) === folio)?.texto!);
            })
            setDataCaso(casos);
            setCasosList(casosText);
            setselectCasos(casosSelected);
        }
    });

    const onSelectCaso = (event: SelectChangeEvent<typeof selectCasos>) => {
        let casos = dataCM.datosCoberturas.prioridadCoberturas;
        let prioridades: prioridadCoberturaType[] = [...dataCM.dataPrioridad];

        const {
            target: { value },
        } = event;

        if (cobertureType === 'GES') {
            casos[cobType] = [];
            if (typeof value === 'string') {
                let casoGES = datacaso?.find(caso => caso.texto === value);
                casos[cobType].push(Number(casoGES?.folio));
            } else {
                value.forEach(val => {
                    let casoGES = datacaso?.find(caso => caso.texto === val);
                    casos[cobType].push(Number(casoGES?.folio));
                })
            }
        }

        if (cobertureType === 'CAEC') {
            prioridades = prioridades.filter(caso => caso.cobertura !== 2);
            let prioridad = 1;
            casos[cobType] = [];
            if (typeof value === 'string') {
                let casoCAEC = datacaso?.find(caso => caso.texto === value);
                casos[cobType].push(Number(casoCAEC?.folio));
                prioridades.push({
                    idCuenta: dataCM.idCuentaMedica,
                    cobertura: 2,
                    casoGES: null,
                    casoCAEC: Number(casoCAEC?.folio),
                    problemaSalud: null,
                    CIE10: casoCAEC?.diagnosticoCie10!,
                    canasta: null,
                    idBeneficios: null,
                    prioridad: prioridad
                })
            } else {
                value.forEach(val => {
                    let casoCAEC = datacaso?.find(caso => caso.texto === val);
                    casos[cobType].push(Number(casoCAEC?.folio));
                    prioridades.push({
                        idCuenta: dataCM.idCuentaMedica,
                        cobertura: 2,
                        casoGES: null,
                        casoCAEC: Number(casoCAEC?.folio),
                        problemaSalud: null,
                        CIE10: casoCAEC?.diagnosticoCie10!,
                        canasta: null,
                        idBeneficios: null,
                        prioridad: prioridad
                    });
                    prioridad++;
                })
            }
        }

        setDataCM({ ...dataCM, datosCoberturas: { cobertureValues: dataCM.datosCoberturas.cobertureValues, prioridadCoberturas: casos }, dataPrioridad: prioridades });
        setselectCasos(value);
    }

    return (
        <Grid mt={'20px'}>
            <Box sx={formHeader}>
                <Grid container>
                    <Grid xs={3}>
                        <Stack direction={"column"}>
                            <Typography sx={titleAccordion}>Tipo de Cobertura</Typography>
                            <Typography sx={textAccordion} pt="2px">{cobertureText}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={9}>
                        {cobertureType === 'PLAN' ? null :
                            <FormControl fullWidth>
                                <SelectMultiple
                                    disabled={disabled}
                                    loading={loading}
                                    selectedValues={selectCasos}
                                    values={casosList}
                                    onChange={onSelectCaso}
                                    placeholder={(cobertureType === 'GES' ? 'Caso Ges - Problema de Salud' : 'Caso CAEC - Diagnóstico CAEC (Cie10)') + ", Seleccione por orden de prioridad"}
                                />
                            </FormControl>}
                    </Grid>
                </Grid>
            </Box>
            <TransitionGroup>
                {!loading && cobertureType === 'GES' &&
                    datacaso.length > 0 &&
                    dataCM.datosCoberturas.prioridadCoberturas[cobertureType].map((caso: number, index: number) => {
                        const data = datacaso.find(item => Number(item.folio) === caso);
                        return (
                            <Collapse key={caso}>
                                <CasosSelector dataCM={dataCM} setDataCM={setDataCM} index={index} cobertureType={cobertureType} dataCaso={data!} disabled={disabled || loading} />
                            </Collapse>
                        )
                    }
                    )}
            </TransitionGroup>
            {!loading && cobertureType === 'CAEC' && <TableDataCoberture isCaec dataCM={dataCM} dataCasos={datacaso} />}
        </Grid>
    )
}