import { Accordion, AccordionSummary, Typography, Grid, AccordionDetails, FormControl, SelectChangeEvent } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from "@mui/system"
import { inputForm, titleAccordion } from "../../../../assets/styles/Text/text"
import { useState } from "react"
import { ObtenerCasosGESCAEC } from "./coberturaSpan"
import { SelectMultiple } from "../../../../components/Selects/SelectMultiple"
import { prioridadCoberturaType } from "./dataCoberturaEsencial"
import { TableDataCoberture } from "./TableData"

type CasosSelectorProps = {
    dataCM: any
    setDataCM: Function
    index: number
    cobertureType: string
    dataCaso: ObtenerCasosGESCAEC
    disabled?: boolean
}

const findCanastas = (prioridad: prioridadCoberturaType[], caso: ObtenerCasosGESCAEC) => {
    const canastas = prioridad.filter((priority) => priority.casoGES === Number(caso.folio));
    return canastas.map(can => {
        const canasta = caso.canastas.find(item => item.canasta === can.canasta);
        return `Canasta ${can.canasta} - ${canasta!.descripcioncanasta}`
    });
}

export const CasosSelector = ({ dataCM, setDataCM, index, cobertureType, dataCaso, disabled }: CasosSelectorProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const casoID = dataCaso.folio;
    const dataCanastas = dataCaso!.canastas?.map(can => `Canasta ${can.canasta} - ${can.descripcioncanasta}`) || [];
    const [canastas, setCanastas] = useState<string | string[]>(findCanastas(dataCM.dataPrioridad, dataCaso));

    const onCanastaSelect = (event: SelectChangeEvent<typeof canastas>) => {
        let casos: prioridadCoberturaType[] = [...dataCM.dataPrioridad];
        casos = casos.filter(caso => caso.casoGES !== Number(casoID));
        const {
            target: { value },
        } = event;
        let prioridad = 1;
        if (typeof value === 'string') {
            let canastaSelect = dataCaso?.canastas.find(can => `Canasta ${can.canasta} - ${can.descripcioncanasta}` === value);
            casos.push({
                idCuenta: dataCM.idCuentaMedica,
                cobertura: 1,
                casoGES: Number(casoID),
                casoCAEC: null,
                problemaSalud: Number(dataCaso?.problemaSalud!),
                CIE10: null,
                canasta: canastaSelect?.canasta!,
                idBeneficios: canastaSelect?.idBeneficios!,
                prioridad: prioridad
            })
        } else {
            value.forEach(val => {
                let canastaSelect = dataCaso?.canastas.find(can => `Canasta ${can.canasta} - ${can.descripcioncanasta}` === val);
                casos.push({
                    idCuenta: dataCM.idCuentaMedica,
                    cobertura: 1,
                    casoGES: Number(casoID),
                    casoCAEC: null,
                    problemaSalud: Number(dataCaso?.problemaSalud!),
                    CIE10: null,
                    canasta: canastaSelect?.canasta!,
                    idBeneficios: canastaSelect?.idBeneficios!,
                    prioridad: prioridad
                });
                prioridad++;
            })
        }

        setDataCM({ ...dataCM, dataPrioridad: casos });
        setCanastas(value);
    }

    return (
        <Grid marginTop={'20px'}>
            <Accordion expanded={expanded} disableGutters key={casoID}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ backgroundColor: '#F2F5F9!important' }}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Grid container>
                        <Grid xs={3}>
                            <Stack alignItems={"center"} direction={"row"} spacing={"10px"}>
                                <Typography sx={titleAccordion}>Caso {cobertureType}: (N°{index + 1})</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={9}>
                            <Stack alignItems={"center"} direction={"row"} spacing={"10px"} pl={1}>
                                <Typography sx={inputForm}>Selector de canastas</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid xs={3}>
                            <Stack alignItems={"center"} direction={"row"} spacing={"10px"}>
                                <Typography sx={inputForm}>Folio {dataCaso?.folio} - {dataCaso?.problemaSalud + " " + dataCaso?.glosaProblemaSalud}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl fullWidth>
                                <SelectMultiple
                                    disabled={disabled}
                                    selectedValues={canastas}
                                    values={dataCanastas}
                                    onChange={onCanastaSelect}
                                    placeholder="Seleccione por orden de prioridad"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <TableDataCoberture dataCM={dataCM} casoGES={casoID} canastas={dataCaso.canastas} />
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}