import { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Paginador } from "../../../../components/TablePagination/Paginador";
import { boldTitleBox } from "../../../../assets/styles/Text/text";
import { NoResult } from "../../../../components/Tables/noResult";
import { NetworkStatus, useQuery } from "@apollo/client";
import { consultaDetallePrestaciones } from "../../../../services/administracionCM/valorizacionService";
import { LoaderTable } from "./loaderValorizacion";
import { verificaLargo } from "../../../../components/Tooltip/Tooltip";
import { ForzarBonif } from "./forzarBonif";
import { useValorizationContext } from "../../../../context/valorizationContext";
import { parseMiles } from "../../../../utils/monedaUtils";
import { TablaAutorizacion } from "./tablaAutorizacion";
import { MenuAccionesPrestaciones } from "./menuAccionesPrestaciones";

const estadoCero = {
    contador: 0,
    prestaciones: []
}

const TableValorizacion = ({
    prestacion,
    idCuenta,
    idCobro,
    idCobertura,
    muestraMensaje,
    abrirNuevaPrest,
    disabled,
    refetchDoc,
    isBonif,
    tipoCobertura,
    tipoDoc,
    estadoValorizacion,
    estadoBonificacion,
    folioCobertura,
    isCuentaAuth,
    authFilter,
    objModalPrestacion }: any) => {
    const [dataTabla, setDataTabla] = useState<any[]>(estadoCero.prestaciones);
    const [contador, setContador] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {
        selectedDetailItem,
        selectedDocTypes,
        setSelectedDetailItem,
        setSelectedDocTypes,
    } = useValorizationContext();

    const { loading, refetch, networkStatus } = useQuery(consultaDetallePrestaciones, {
        variables: {
            idCuenta,
            idCobro,
            idCobertura,
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            prestacion: prestacion,
            estadoAutorizacion: authFilter ? isCuentaAuth ? [2] : [2, 3] : null
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (res) => {
            setDataTabla(res.obtenerDetallePrestacion.prestaciones);
            setContador(res.obtenerDetallePrestacion.contador);
        },
        onError: (err) => {
            setDataTabla(estadoCero.prestaciones);
            setContador(estadoCero.contador);
            console.log(err);
        }
    });

    const resetSelectedItems = () => {
        setSelectedDetailItem([]);
        setSelectedDocTypes({ CAEC: 0, GES: 0, "GES-CAEC": 0, PLAN: 0 });
    }
    const handleChangePage = (newPage: number) => {
        setDataTabla(estadoCero.prestaciones);
        setContador(estadoCero.contador);
        setPage(newPage);
        resetSelectedItems();
    };

    const handleChangeRowsPerPage = (value: number) => {
        setRowsPerPage(value);
        setPage(0);
        resetSelectedItems();
    };

    const detailsIdToAdd = dataTabla
        .map((detailItem) => detailItem.id)
        .filter((detailItemId) => !selectedDetailItem.includes(detailItemId));
    const detailsIdInTable = dataTabla.map(
        (detailItem) => detailItem.id
    );
    const detailsIdToRemove = selectedDetailItem.filter(
        (detailItem) => !detailsIdInTable.includes(detailItem)
    );
    const selectedIdsOnTable = detailsIdInTable.filter(
        id => selectedDetailItem.includes(id)
    );

    const handleCheckAll = (e: any) => {
        const updatedSelectedDocType = { ...selectedDocTypes };
        if (e.target.checked) {

            setSelectedDetailItem([...selectedDetailItem, ...detailsIdToAdd]);
            updatedSelectedDocType[tipoCobertura] =
                (updatedSelectedDocType[tipoCobertura] || 0) + detailsIdToAdd.length;
        } else {

            setSelectedDetailItem(detailsIdToRemove);
            updatedSelectedDocType[tipoCobertura] =
                (updatedSelectedDocType[tipoCobertura] || 0) - detailsIdInTable.length;
        }
        setSelectedDocTypes(updatedSelectedDocType);
    };

    const handleCheck = (detailId: number) => {
        const updatedSelectedDocType = { ...selectedDocTypes };
        const isSelected = selectedDetailItem.includes(detailId);
        setSelectedDetailItem(
            isSelected
                ? selectedDetailItem.filter((val) => val !== detailId)
                : [...selectedDetailItem, detailId]
        );
        const updateSelectedDocType = (type: string, increment: number) => {
            updatedSelectedDocType[type] =
                (updatedSelectedDocType[type] || 0) + increment;
        };
        if (tipoCobertura === "PLAN")
            updateSelectedDocType("PLAN", isSelected ? -1 : 1);
        if (tipoCobertura === "GES")
            updateSelectedDocType("GES", isSelected ? -1 : 1);
        if (tipoCobertura === "CAEC")
            updateSelectedDocType("CAEC", isSelected ? -1 : 1);
        if (tipoCobertura === "GES-CAEC")
            updateSelectedDocType("GES-CAEC", isSelected ? -1 : 1);

        setSelectedDocTypes(updatedSelectedDocType);
    };

    return (
        <Paper className="paper-tables-2">
            {loading &&
                !(
                    networkStatus === NetworkStatus.poll ||
                    networkStatus === NetworkStatus.refetch ||
                    networkStatus === NetworkStatus.ready
                ) ? <LoaderTable rows={rowsPerPage} /> :
                <>
                    <button hidden id={`btnCobro${idCobro}`} name="btnRefetchCobro" onClick={() => refetch()} />
                    {authFilter ?
                        <TablaAutorizacion
                            dataTabla={dataTabla}
                            disabled={disabled || estadoValorizacion !== 53 || ![53, 54, 55].includes(estadoBonificacion)}
                            tipoCobertura={tipoCobertura}
                            tipoDoc={tipoDoc}
                            muestraMensaje={muestraMensaje}
                            refetch={refetch}
                            refetchDoc={refetchDoc}
                            isCuentaAuth={isCuentaAuth}
                            authFilter={authFilter}
                        />
                        :
                        <TableContainer className="tContainer-padding-0" component={Paper} sx={{ borderTop: "1px solid #E9EBEC", }}>
                            <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                                <TableHead className="color-head-table" >
                                    <TableRow>
                                        {authFilter ? null : <TableCell className="headerCheckSticky" align="center">
                                            <Checkbox
                                                color="secondary"
                                                disabled={disabled || estadoValorizacion === 52 || estadoBonificacion === 52}
                                                onClick={handleCheckAll}
                                                checked={dataTabla.length > 0 && dataTabla.length === selectedIdsOnTable.length}
                                                indeterminate={selectedIdsOnTable.length > 0 && selectedIdsOnTable.length < dataTabla.length}
                                            />
                                        </TableCell>}
                                        <TableCell className="headAccordion" align="center" width={"190px"}>Código prestador</TableCell>
                                        <TableCell className="headAccordion" align="center" width={"200px"}>Código prestación</TableCell>
                                        <TableCell className="headAccordion" align="center" width={"400px"}>Glosa prestación</TableCell>
                                        {isBonif && authFilter ? <TableCell className="headAccordion" align="center" width={"139px"}>Estado</TableCell> : null}
                                        <TableCell className="headAccordion" align="center" width={"400px"}>Folio Cobertura</TableCell>
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>Cobertura</TableCell> : null}
                                        <TableCell className="headAccordion" align="center" width={"400px"}>Código Canasta</TableCell>
                                        <TableCell className="headAccordion" align="center" width={"80px"}>Item</TableCell>
                                        <TableCell className="headAccordion" align="center" width={"100px"}>Cantidad</TableCell>
                                        <TableCell className="headAccordion" align="center" width={"160px"}>Total Prestación</TableCell>
                                        {isBonif ? null : <TableCell className="headAccordion" align="center" width={"139px"}>Monto Unitario Convenio</TableCell>}
                                        {isBonif ? null : <TableCell className="headAccordion" align="center" width={"400px"}>Total Convenio</TableCell>}
                                        {isBonif ? null : <TableCell className="headAccordion" align="center" width={"139px"}>% Diferencia</TableCell>}
                                        {isBonif ? null : <TableCell className="headAccordion" align="center" width={"139px"}>Urgencia</TableCell>}
                                        <TableCell className="headAccordion" align="center" width={"139px"}>Tipo Atención</TableCell>
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>N° Intervención</TableCell> : null}
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>Monto bonif.<br></br> plan</TableCell> : null}
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>Monto bonif.<br></br> topado</TableCell> : null}
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>Monto bonif.<br></br> forzada</TableCell> : null}
                                        {isBonif ? <TableCell className="headAccordion" align="center" width={"139px"}>Monto Copago</TableCell> : null}
                                        {isBonif ? <TableCell className={isCuentaAuth ? "headerTableEspecial columnAccionAuth" : "headerTableEspecial columnAccion2"} align="center">Forzar <br></br>Monto</TableCell> : null}
                                        {isCuentaAuth ? null : <TableCell className="headerAccion headerAction" align="center">Acción</TableCell>}
                                    </TableRow>
                                </TableHead>
                                {dataTabla.length > 0 ?
                                    <TableBody>
                                        {dataTabla.map((pres: any) => {
                                            const isSelected = selectedDetailItem.includes(pres.id);
                                            const diferencia = Math.abs(pres.montoPrestacionCobro || 0) - (pres.montoPrestacionConvenioTotal || 0);
                                            const porcentajeCobro = pres.montoPrestacionConvenioTotal ? Math.round((diferencia / pres.montoPrestacionConvenioTotal) * 100) : pres.montoPrestacionCobro === 0 ? 0 : 100;
                                            return (
                                                <TableRow key={pres.id} sx={{ backgroundColor: pres.beneficioComplementario ? "#E6F6F5 !important" : "" }}>
                                                    {authFilter ? null : <TableCell align="center" className="BodyCheckSticky" sx={{ borderRight: "1px solid #D2D6D9" }}>
                                                        <Checkbox color="secondary" disabled={disabled || estadoValorizacion === 52 || estadoBonificacion === 52} checked={isSelected} onChange={() => handleCheck(pres.id)} />
                                                    </TableCell>}
                                                    <TableCell className="headAccordion" align="center">
                                                        {pres.codigoPrestacionPrestador || '-'}
                                                    </TableCell>
                                                    <TableCell className="headAccordion" align="center">
                                                        {pres.codigoPrestacionIsapre}
                                                    </TableCell>
                                                    <TableCell className="headAccordion" align="center">
                                                        {verificaLargo(pres.glosaPrestacionIsapre || "", 25)}
                                                    </TableCell>
                                                    {isBonif && authFilter ?
                                                        <TableCell className="headAccordion" align="center">
                                                            {pres.glosaEstadoAutorizacion || ""}
                                                        </TableCell>
                                                        : null}
                                                    <TableCell className="headAccordion" align="center">
                                                        {folioCobertura || "-"}
                                                    </TableCell>
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {pres.cobertura || "Sin cobertura"}
                                                    </TableCell> : null}
                                                    <TableCell className="headAccordion" align="center">
                                                        {pres.codigoCanasta || "-"}
                                                    </TableCell>
                                                    <TableCell className="headAccordion" align="center">
                                                        {pres.idItemIsapre}
                                                    </TableCell>
                                                    <TableCell className="headAccordion" align="center">
                                                        {pres.frecuenciaAtencion}
                                                    </TableCell>
                                                    <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoPrestacionCobro || 0)}
                                                    </TableCell>
                                                    {isBonif ? null : <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoPrestacionConvenio || 0)}
                                                    </TableCell>}
                                                    {isBonif ? null : <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoPrestacionConvenioTotal || 0)}
                                                    </TableCell>}
                                                    {isBonif ? null : <TableCell className="headAccordion" align="center">
                                                        {porcentajeCobro || 0}%
                                                    </TableCell>}
                                                    {isBonif ? null : <TableCell className="headAccordion" align="center">
                                                        {validaUrgencia(pres.atencionUrgencia)}
                                                    </TableCell>}
                                                    <TableCell className="headAccordion" align="center">
                                                        {validaAtencion(pres.tipoAtencion)}
                                                    </TableCell>
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {pres.numeroOrdenIntervencion || ""}
                                                    </TableCell> : null}
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoBonificadoPlan || 0)}
                                                    </TableCell> : null}
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoBonificacionTopada || 0)}
                                                    </TableCell> : null}
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoBonificacionForzado || 0)}
                                                    </TableCell> : null}
                                                    {isBonif ? <TableCell className="headAccordion" align="center">
                                                        {parseMiles(pres.montoCopago || 0)}
                                                    </TableCell> : null}
                                                    {isBonif ?
                                                        <TableCell className={isCuentaAuth ? "tableBodyEspecial columnAccionAuth" : "tableBodyEspecial columnAccion2"} align="center">
                                                            <ForzarBonif
                                                                row={pres}
                                                                tipoCobertura={tipoCobertura}
                                                                tipoDoc={tipoDoc}
                                                                showMessage={muestraMensaje}
                                                                refetch={refetch}
                                                                refetchDoc={refetchDoc}
                                                                disabled={disabled || estadoValorizacion !== 53 || ![53, 54, 55].includes(estadoBonificacion)}
                                                                montoForzado={pres.montoBonificacionForzado}
                                                                isCuentaAuth={isCuentaAuth}
                                                                authFilter={authFilter}
                                                            />
                                                        </TableCell>
                                                        : null}
                                                    {isCuentaAuth ?
                                                        null :
                                                        <TableCell className="BodyAcciones headerAction" align="center">
                                                            <MenuAccionesPrestaciones
                                                                row={pres.codigoPrestacionIsapre}
                                                                prestacion={pres}
                                                                isBonif={isBonif}
                                                                refetch={() => { refetch(); refetchDoc(); }}
                                                                muestraMensaje={muestraMensaje}
                                                                disabled={disabled || estadoValorizacion === 52 || estadoBonificacion === 52}
                                                                objModalPrestacion={objModalPrestacion}
                                                            />
                                                        </TableCell>}
                                                </TableRow>)
                                        })}
                                    </TableBody> : null
                                }
                            </Table>
                            {dataTabla.length === 0 && prestacion ?
                                <Grid item xs={12} pt={"32px"}>
                                    <NoResult
                                        noDivider={true}
                                        newDontSearch
                                        titulo={"No se encontró información asociada al código ingresado"}
                                        subtitulo={
                                            "Inténtelo nuevamente usando otro criterio"
                                        }
                                        error={true}
                                    />
                                </Grid>
                                : null}
                            {dataTabla.length === 0 && !prestacion ?
                                <Box p="32px" alignItems={"center"} textAlign={"center"}>
                                    <Typography sx={boldTitleBox}>Documento sin prestaciones</Typography>
                                    <Typography pt="8px" pb="16px">Este documento no tiene prestaciones asociadas, para añadir una nueva,<br></br>
                                        {isBonif ? "ve a la pestaña de valorización." : "haz clic en el botón “Añadir prestación”"}</Typography>
                                    {isBonif ? null : <Button variant="outlined" onClick={abrirNuevaPrest} disabled={disabled}>Nueva prestación</Button>}
                                </Box>
                                : null}
                        </TableContainer>}
                    <Box pt="24px">
                        <Paginador
                            data={contador}
                            itemPorPagina={rowsPerPage}
                            opciones={[10, 25, 50, 100]}
                            pagina={page}
                            setLineasPagina={handleChangeRowsPerPage}
                            setPagina={handleChangePage}
                        />
                    </Box>
                </>}
        </Paper>
    );
}

const validaUrgencia = (val: string | null) => {
    if (val) {
        return val === "S" ? "Sí" : "No";
    } else {
        return ""
    }
}

const validaAtencion = (val: string | null) => {
    if (val) {
        return val === "HO" ? "Hospitalario" : "Ambulatoria";
    } else {
        return "Sin Cob. Plan"
    }
}

export default TableValorizacion;
