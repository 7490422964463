import { format } from "date-fns";
import { dataCobertura, prioridadCoberturaType } from "../dataCobertura/dataCoberturaEsencial";

export const creaObjetReq = async (dataCM: any) => {
    let fechaprob: string | null = null;
    if (dataCM.fechaProbableLiquidacion) {
        const [day, month, year] = dataCM.fechaProbableLiquidacion.split("-");
        fechaprob = `${year}-${month}-${day}`;
    }
    const { id, numero, version, fechaInicioVigencia, fechaTerminoVigencia, codigoPlanSalud, glosaPlanSalud } = dataCM.poliza || null;

    const dataPrioridad = await createPriorityCobertureOrder(dataCM.dataPrioridad, dataCM.datosCoberturas);
    const response = {
        rutBeneficiario: dataCM.datosBeneficiario.rut.replace(/[^0-9kK-]/g, ""),
        nombreBeneficiario: dataCM.datosBeneficiario.nombre,
        apellidoPaternoBeneficiario: dataCM.datosBeneficiario.apellidoPaterno,
        apellidoMaternoBeneficiario: dataCM.datosBeneficiario.apellidoMaterno,

        cie10Primario: dataCM.datosBeneficiario.diagnosticoPrimario.codigo,
        descCie10Primario: dataCM.datosBeneficiario.diagnosticoPrimario.descripcion,
        cie10Secundario: dataCM.datosBeneficiario.diagnosticoSecundario.codigo,
        descCie10Secundario: dataCM.datosBeneficiario.diagnosticoSecundario.descripcion,

        diagnosticoMedico: dataCM.datosBeneficiario.diagnosticoMedico,

        rutMedicoTratante: dataCM.datosPrestador.rutMedicoTratante ? dataCM.datosPrestador.rutMedicoTratante.replace(/[^0-9kK-]/g, "") : null,
        nombreMedicoTratante: dataCM.datosPrestador.nombreMedicoTratante,

        rutPrestador: dataCM.datosPrestador.rutPrestador.replace(/[^0-9kK-]/g, ""),
        numeroCuenta: dataCM.datosPrestador.numeroCuenta,
        numeroCobro: dataCM.datosPrestador.numeroCobro.toString(),
        razonSocial: dataCM.datosPrestador.razonSocial,
        tieneConvenio: false,
        canalOrigen: dataCM.canalOrigen,

        codigoPrestacionPrincipalPrestador: dataCM.prestacionPrincipal ? dataCM.prestacionPrincipal.codigo : null,
        descripcionPrestacionPrincipalPrestador: dataCM.prestacionPrincipal ? dataCM.prestacionPrincipal.descripcion : null,
        fechaRecepcion: dataCM.fechaRecepcion ? format(dataCM.fechaRecepcion!, "yyyy-MM-dd") : null,
        fechaProbableLiquidacion: fechaprob,

        fechaInicioHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio!, "yyyy-MM-dd"),
        fechaEgresoHospitalizacion: dataCM.fechaHospitalizacion.fechaEgreso ? format(dataCM.fechaHospitalizacion.fechaEgreso, "yyyy-MM-dd") : null,

        horaInicioHospitalizacion: format(dataCM.fechaHospitalizacion.fechaInicio!, "HH : mm"),
        horaEgresoHospitalizacion: dataCM.fechaHospitalizacion.fechaEgreso ? format(dataCM.fechaHospitalizacion.fechaEgreso, "HH : mm") : null,
        condicionAltaPaciente: dataCM.fechaHospitalizacion.condicionEgreso,

        fechaInicioCobro: format(dataCM.fechaCobro.fechaInicio!, "yyyy-MM-dd"),
        fechaTerminoCobro: format(dataCM.fechaCobro.fechaTermino!, "yyyy-MM-dd"),
        montoTotalCobro: parseInt(dataCM.montoCobro),

        tipoCobertura: dataCM.tipoCobertura.active.toString(),
        tipoRegimen: dataCM.tipoRegimen.active.toString(),
        tipoHabitacion: dataCM.tipoHabitacion.active.toString(),
        tipoAtencion: dataCM.tipoAtencion,

        numeroIntervenciones: parseInt(dataCM.cantidadIntervenciones),
        graduacionDeParto: dataCM.graduacionParto ? format(dataCM.graduacionParto!, "yyyy-MM-dd") : null,


        leyUrgenciaVital: dataCM.leyUrgencia,
        esCuentaComplementaria: dataCM.cuentaComplementaria === "S",
        soap: dataCM.soap,
        esCuentaPaquetizada: null,

        declaracionPreexistencia: dataCM.declaracionPreexistencia === "S",
        restriccionCobertura: dataCM.restriccionCobertura === "S",
        tipoCuentaMedica: parseInt(dataCM.tipoCuentaMedica),

        idPoliza: dataCM.poliza.id,
        poliza: dataCM.poliza.id ? {
            id,
            numero,
            version,
            fechaInicioVigencia,
            fechaTerminoVigencia,
            codigoPlanSalud,
            glosaPlanSalud
        } : null,

        reliquidacion: dataCM.reliquidacion === "S",
        cuentaReliquidacionAsociada: dataCM.cuentaReliquidacionAsociada,
        dataPrioridad
    }
    return response;
}

export const createPriorityCobertureOrder = async (dataPrioridad: prioridadCoberturaType[], datosCoberturas: { cobertureValues: string[], prioridadCoberturas: dataCobertura }) => {
    const priorityData: prioridadCoberturaType[] = [];
    let prioridad = 1;
    for (const type of datosCoberturas.cobertureValues) {
        let typeKey = type as keyof dataCobertura;
        if (typeKey === 'PLAN') {
            const plan = dataPrioridad.find(item => item.cobertura === 3);
            priorityData.push({ ...plan!, prioridad: prioridad });
        }
        datosCoberturas.prioridadCoberturas[typeKey].forEach(item => {
            dataPrioridad.forEach(cob => {
                if (typeKey === 'GES') {
                    if (cob.casoGES === item) {
                        priorityData.push({ ...cob, prioridad: prioridad });
                        prioridad++;
                    }
                } else {
                    if (cob.casoCAEC === item) {
                        priorityData.push({ ...cob, prioridad: prioridad });
                        prioridad++;
                    }
                }
            })
        })

    }

    return priorityData
}

export const checkDisabled = (dataCM: any, setisDisable: Function) => {
    if (dataCM) {
        if (!dataCM.datosPrestador.rutPrestador ||
            !dataCM.datosPrestador.razonSocial ||
            !dataCM.datosPrestador.numeroCuenta ||
            !dataCM.datosPrestador.numeroCobro ||
            !dataCM.datosBeneficiario.rut ||
            !dataCM.datosBeneficiario.nombre ||
            !dataCM.datosBeneficiario.diagnosticoPrimario ||
            !dataCM.datosBeneficiario.diagnosticoPrimario.codigo ||
            !dataCM.datosBeneficiario.diagnosticoSecundario ||
            !dataCM.datosBeneficiario.diagnosticoSecundario.codigo ||
            !dataCM.fechaHospitalizacion.fechaInicio ||
            (dataCM.fechaHospitalizacion.fechaEgreso &&
                (!dataCM.fechaHospitalizacion.condicionEgreso ||
                    dataCM.fechaHospitalizacion.fechaInicio >
                    dataCM.fechaHospitalizacion.fechaEgreso)) ||
            !dataCM.fechaProbableLiquidacion ||
            !dataCM.fechaCobro.fechaInicio ||
            !dataCM.fechaCobro.fechaTermino ||
            !dataCM.tipoCobertura.active ||
            !dataCM.tipoRegimen.active ||
            !dataCM.tipoHabitacion.active ||
            !(Number(dataCM.cantidadIntervenciones) >= 0) ||
            !dataCM.montoCobro ||
            !dataCM.leyUrgencia ||
            !dataCM.tipoAtencion ||
            !dataCM.cuentaComplementaria ||
            !dataCM.soap ||
            !dataCM.tipoCuentaMedica ||
            !dataCM.declaracionPreexistencia ||
            !dataCM.restriccionCobertura || checkCoberturesSelects(dataCM.dataPrioridad, dataCM.datosCoberturas)) {
            setisDisable(true);
        } else {
            setTimeout(() => setisDisable(false), 1000);
        }
    }
}

export const checkCoberturesSelects = (dataPrioridad: prioridadCoberturaType[], datosCoberturas: { cobertureValues: string[], prioridadCoberturas: dataCobertura }) => {
    if (datosCoberturas.cobertureValues.length > 0) {
        if (datosCoberturas.cobertureValues.includes('PLAN') && !dataPrioridad.find(item => item.cobertura === 3)) {
            return true
        }
        if (datosCoberturas.cobertureValues.includes('GES')) {
            if (datosCoberturas.prioridadCoberturas.GES.length === 0) {
                return true
            } else {
                const result = datosCoberturas.prioridadCoberturas.GES.map(caso => {
                    if (dataPrioridad.filter(item => item.casoGES === caso).length === 0) {
                        return true
                    } else {
                        return false
                    }
                })
                if(result.includes(true)){
                    return true
                }
            }
        }
        if (datosCoberturas.cobertureValues.includes('CAEC') && datosCoberturas.prioridadCoberturas.CAEC.length === 0) {
            return true
        }
        return false
    } else {
        return true
    }
}