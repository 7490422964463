import { Box, Divider, Stack, Typography } from "@mui/material";
import { boxInfoCMNew } from "../../../assets/styles/Box/boxs";
import { formatRut } from "../../../utils";
import { nombreHeader, textHeader } from "../../../assets/styles/Text/text";
import { verificaLargo } from "../../../components/Tooltip/Tooltip";
import PreExistingConditionsButton from "./preexistingConditionsButton";
import { ButtonStatusAction } from "./ButtonStatusAction/buttonStatusAction";
import iconEdifGreen from "../../../assets/icons/iconEdifGreen.svg";
import DocLittle from "../../../assets/icons/docLittleGreen.svg";
import Profile from "../../../assets/icons/iconProfileGreen.svg";
import { EstadosHistorial } from "../../escritorioTrabajo/components/estadosHistorial";
import SkeletonBar from "../../../components/Skeletons/skeletonBar";

function HeaderCuentaMedica({ dataCM, refreshCuentaMedica, readOnly, loading }: any) {
  const { rut, nombre, apellidoPaterno, apellidoMaterno } =
    dataCM.datosBeneficiario;

  const { idCuentaMedica, poliza, datosPrestador } = dataCM;
  const { fechaInicio } = dataCM.fechaHospitalizacion;
  const formatFechaHeader = (fecha: string) => {
    if (fecha !== null && fecha !== undefined && fecha !== "") {
      const [anio, mes, dia] = fecha.split("/");
      return `${dia}/${mes}/${anio}`;
    } else {
      return "";
    }
  };
  const name = nombre ? nombre.toLowerCase() : "";
  const surname1 = apellidoPaterno ? apellidoPaterno.toLowerCase() : " ";
  const surname2 = apellidoMaterno ? apellidoMaterno.toLowerCase() : "";
  return (
    <Box sx={boxInfoCMNew} width={"100%"}>
      <Stack direction={"row"} width={"auto"} pt="6px" pb="6px">
        <Stack direction={"column"} width={'100%'}>
          <Typography sx={textHeader} pb={"20px"}>
            <img src={Profile} alt="" /> Información Afiliado
          </Typography>
          <Stack direction={"row"} pb={"10px"} justifyContent={'space-between'}>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Nombre afiliado: </Typography>
              <Typography sx={textHeader}>
                {nombre ? verificaLargo(name + " " + surname1 + surname2, 35) : ""}
              </Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>RUT: </Typography>
              <Typography sx={textHeader}>{formatRut(rut || "")}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>N° Cuenta Médica: </Typography>
              <Typography sx={textHeader}>{idCuentaMedica}</Typography>
            </Box>
            <Stack direction={"row"} justifyContent={'right'} width={'50%'}>
              <Divider orientation='vertical' sx={{ mr: '10px' }} />
              <Typography mr={'10px'}>Estado de la cuenta</Typography>
              {
                loading ? <SkeletonBar width={100} height={40} /> : <EstadosHistorial estado={dataCM.estadoCuentaMedica.estado ? dataCM.estadoCuentaMedica.estado : 11}  />
              }
            </Stack>
          </Stack>
          <Divider sx={{ mb: "20px" }} />
          <Typography sx={textHeader} pb={"20px"}>
            <img src={DocLittle} alt="" /> Información Prestador
          </Typography>
          <Stack direction={"row"} pb={"10px"}>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Razón Social: </Typography>
              <Typography sx={textHeader}>{datosPrestador.razonSocial}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>RUT: </Typography>
              <Typography sx={textHeader}>{datosPrestador.rutPrestador}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>N° Cuenta Prestador: </Typography>
              <Typography sx={textHeader}>{datosPrestador.numeroCuenta}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>N° Cobro Prestador: </Typography>
              <Typography sx={textHeader}>{datosPrestador.numeroCobro}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>N° Envío Prestador: </Typography>
              <Typography sx={textHeader}>{datosPrestador.numeroEnvio}</Typography>
            </Box>
          </Stack>
          <Divider sx={{ mb: "20px" }} />
          <Typography sx={textHeader} pb={"20px"}>
            <img src={iconEdifGreen} alt="" /> Información Póliza
          </Typography>
          <Stack direction={"row"} pb={"10px"}>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Poliza: </Typography>
              <Typography sx={textHeader}>{poliza.numero}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Versión: </Typography>
              <Typography sx={textHeader}>{poliza.version}</Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Ini. Vigencia: </Typography>
              <Typography sx={textHeader}>
                {formatFechaHeader(poliza.fechaInicioVigencia)}
              </Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Ter. Vigencia: </Typography>
              <Typography sx={textHeader}>
                {formatFechaHeader(poliza.fechaTerminoVigencia)}
              </Typography>
            </Box>
            <Box pr="24px">
              <Typography sx={nombreHeader}>Plan de Salud: </Typography>
              <Typography sx={textHeader}>{poliza.glosaPlanSalud}</Typography>
            </Box>
          </Stack>
          <Divider sx={{ mb: "20px" }} />
          <Stack direction={"row"} justifyContent={'right'}>
            <Box>
              {rut && fechaInicio ? (
                <PreExistingConditionsButton
                  rut={rut}
                  fechaInicio={fechaInicio}
                />
              ) : null}
            </Box>
            {dataCM.estadoCuentaMedica.estado && (
              <Box pl="24px">
                <ButtonStatusAction
                  idCM={Number(dataCM.idCuentaMedica)}
                  currentStatus={dataCM.estadoCuentaMedica.estado}
                  channel={dataCM.canalOrigen}
                  refreshCuentaMedica={refreshCuentaMedica}
                  CMUser={dataCM.idUsuario}
                  readOnly={readOnly}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default HeaderCuentaMedica;
