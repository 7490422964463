import { MenuItem, Select, Typography } from '@mui/material';
import { CustomSelectInput, SlimSelectInput, menuFirstStyle, menuItemStyle, menuSelectStyle } from '../../assets/styles/Select/Select';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

export const ValueSelect = (props: any) => { 

    return (
        <Select
            onChange={props.onChange}
            disabled={props.disabled}
            value={props.data?.length > 0 ? props.value || 0 : 0}
            sx={{ width: '100%', textTransform: 'capitalize' }}
            input={<CustomSelectInput className={props.borderColor ? "border-color" : ""} />}
            MenuProps={menuSelectStyle}
            name={props.name}
            IconComponent={KeyboardArrowDownRoundedIcon}
        >
            <MenuItem disabled key={0} value={0} className='FirstMenu' sx={menuFirstStyle}> <Typography sx={menuFirstStyle}>{props.titulo}</Typography> </MenuItem>
            {props.data?.map((elem: any) => (
                <MenuItem
                    key={elem}
                    value={elem}
                    sx={menuItemStyle}
                >
                    {elem.nombre ? elem.nombre : elem}
                </MenuItem>
            ))}
        </Select >
    )
} 

export const SlimValueSelect2 = ({onChange,disabled, value, borderColor, name, data, titulo }: any) => { 
    return (
        <Select
            onChange={onChange}
            disabled={disabled}
            value={value || "-99"}
            sx={{ width: '100%', textTransform: 'capitalize' }}
            input={<SlimSelectInput className={borderColor ? "border-color" : ""} />}
            MenuProps={menuSelectStyle}
            name={name}
            IconComponent={KeyboardArrowDownRoundedIcon}
        >
            <MenuItem disabled key={"-99"} value={"-99"} className='FirstMenu' sx={menuFirstStyle} > <Typography sx={menuFirstStyle} >{titulo}</Typography> </MenuItem>
            {data?.map((elem: any) => (
                <MenuItem
                    key={JSON.stringify(elem)}
                    value={elem.value || elem.id || elem.idParametroInterno}
                    sx={menuItemStyle}
                >
                    {elem.label || elem.nombre || elem.valorParametro}
                </MenuItem>
            ))}
        </Select >
    )
} 
