import { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { ButtonGo } from "../../components/Buttons/ButtonGo";
import TabcuentaMedica from "./tabCuentaMedica/tabcuentaMedica";
import HeaderCuentaMedica from "./headerCuentaMedica/headerCuentaMedica";
import { useLazyQuery } from "@apollo/client";
import { getCuentaMedicasQuery } from "../../services/administracionCM/administracionCMServices";
import LoadFiles from "./loadFiles/loadFiles";
import EventoHospitalario from "./eventoHO/eventoHospitalario";
import { TabsValues } from "./tabsValues";
import { useGetUser } from "../../hooks/getUser";
import { validateACMPerms } from "../../utils/hasAccess";
import { LoadIngresoCuenta } from "./loaders/skeletonsIngresoCuenta";
import { useLocation, useNavigate } from "react-router-dom";
import { parseData } from "./utils/parseData";
import { modalTextBold, warningGray, warningPurple } from "../../assets/styles/Text/text";
import { WarningModalCustom } from "../../components/Modal/warningModalCustom";
import SnackBar from "../../components/Snackbar/Snackbar";
import { Valorizacion } from "./valorizacion/valorizacion";
import { ValorizationProvider } from "../../context/valorizationContext";
import { NuevosEstadosCuentaMedica } from "../../utils/enums";
import { initialState } from "./utils/estadoCero";

const WarningModalContent = ({ dataCM }: any) => {
  return (
    <>
      <br></br>
      <Typography sx={warningGray}>
        Existen cambios sin guardar, al salir del formulario perderás <br></br>
        el progreso correspondientes
      </Typography>{" "}<br></br>
      <Typography sx={warningPurple} pb="16px">
        RUT: {dataCM.datosBeneficiario.rut} {dataCM.idCuentaMedica ? `Nº Cuenta médica: ${dataCM.idCuentaMedica}` : ''}
      </Typography>
      <br></br>{" "}
      <Typography sx={modalTextBold} >
        ¿Estás seguro de continuar con está acción?
      </Typography>
    </>
  );
};

function AdministracionCM() {
  const location = useLocation();
  const navigate = useNavigate();
  if (!location.state) {
    navigate("/Escritorio");
  }

  const data: any = location.state;
  const { email, rol } = useGetUser();
  const [tabIndex, setTabIndex] = useState("1");
  const [disabledTabs, setDisabledTabs] = useState(true);
  const [disabledByDefault] = useState<boolean>(true);
  const [openNeededInfoModal, setOpenNeededInfoModal] = useState(false);
  const [dataCM, setDataCM] = useState<any>(initialState);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [canEditAG, setCanEditAG] = useState<boolean>(false);
  const [canEditEH, setCanEditEH] = useState<boolean>(false);
  const [canEditDA, setCanEditDA] = useState<boolean>(false);
  const [canEditVal, setCanEditVal] = useState<boolean>(false);
  const [canEditBonf, setCanEditBonf] = useState<boolean>(false);
  const [cargaCuenta, setCargaCuenta] = useState<boolean>(false);
  const [isDataSaved, setIsDataSaved] = useState<boolean>(true);
  const [staticData, setStaticData] = useState<any>(initialState);
  const [openWarning, setOpenWarning] = useState(false);
  const [dataTab, setDataTab] = useState<string>("1");
  const [snackData, setSnackData] = useState<any>({
    abrir: false,
    mensaje: "",
    severidad: "info"
  });

  const [fnGet, { loading }] = useLazyQuery(getCuentaMedicasQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      const parsedDataCM = parseData(res.admCuentaMedica);
      setDataCM({
        ...dataCM,
        ...parsedDataCM,
        vigenciaPoliza: true
      });
      setStaticData({
        ...dataCM,
        ...parsedDataCM,
        vigenciaPoliza: true
      })
      if (data.readOnly || res.admCuentaMedica.idUsuario !== email || res.admCuentaMedica.estado > NuevosEstadosCuentaMedica.Bonificación) {
        setReadOnly(true);
      } else {
        validateACMPerms(parsedDataCM.estadoCuentaMedica.estado, rol, setCanEditAG, setCanEditEH, setCanEditDA, setCanEditVal, setCanEditBonf);
      }
      if (data.isAuth) {
        setTabIndex(TabsValues.bonificacion2);
      }
      setDisabledTabs(false);
    },
    onError: (err) => console.log(err)
  });

  const getCuentaMedica = (id: number | undefined) => {
    fnGet({ variables: { idCM: Number(data.idCuentaMedica || dataCM.idCuentaMedica || id) } });
  };

  if (!cargaCuenta) {
    if (data.idCuentaMedica) {
      setDataCM({ ...dataCM, ...data });
      getCuentaMedica(undefined);
      setCargaCuenta(true);
    } else {
      setDataCM({ ...dataCM, idCuentaMedica: null, canalOrigen: "EMAIL" });
      setDisabledTabs(true);
      setCargaCuenta(true);
      validateACMPerms(NuevosEstadosCuentaMedica.Recepcionada, rol, setCanEditAG, setCanEditEH, setCanEditDA, setCanEditVal, setCanEditBonf);
    }
  }

  useEffect(() => {
    if (JSON.stringify(staticData) !== JSON.stringify(dataCM)) {
      setIsDataSaved(false);
    } else {
      setIsDataSaved(true);
    }
  }, [dataCM, staticData])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (!isDataSaved) {
      setDataTab(newValue);
      setOpenWarning(true);
    } else {
      setTabIndex(newValue);
    }
  };

  const invalSecurityCheck = () => {
    setOpenWarning(false);
    setTabIndex(dataTab);
    setDataTab("1");
    setDataCM(staticData);
  }

  const createMsg = (severity: string, content: string) => {
    setSnackData({
      abrir: true,
      mensaje: content,
      severidad: severity
    });
  };

  const updateHO = (idEvent: any) => {
    setDataCM({ ...dataCM, idEventoHospitalario: idEvent });
    setStaticData({ ...staticData, idEventoHospitalario: idEvent });
  }

  return (
    <TabContext value={tabIndex}>
      <SnackBar state={snackData} setState={setSnackData} />
      <button hidden id="btnRefetchCuenta" onClick={() => getCuentaMedica(undefined)} />
      <WarningModalCustom
        secondaryButton="Sí, Continuar"
        primaryButton="No, Volver"
        contentText={<WarningModalContent dataCM={dataCM} />}
        title="Cambios sin guardar"
        headWarning
        onOpen={openWarning}
        onConfirm={invalSecurityCheck}
        onClose={() => setOpenWarning(false)}
        loadingWidth={"120px"}
      />
      <Box width="100%">
        <Box width="88%" margin="auto" pt="16px">
          <ButtonGo
            dataUrl={data.isConsulta ? "/ConsultaCuentas" : "/Escritorio"}
            isConsulta={data.isConsulta}
          />

          <Box height="52px" display="flex" alignItems="flex-end">
            <Typography variant="h2">
              Administración de Cuenta Médica
            </Typography>
          </Box>

          <Box display="flex" mt="16px" mb="20px">
            <HeaderCuentaMedica
              dataCM={dataCM}
              refreshCuentaMedica={getCuentaMedica}
              readOnly={readOnly}
              loading={loading}
            />
          </Box>

          <Box width="99%">
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              variant="scrollable"
            >
              <Tab
                label="Antec. Generales"
                value={TabsValues.antecedentesGenerales}
              />
              <Tab
                label="Evento HO"
                value={TabsValues.eventoHospitalario}
                disabled={disabledTabs}
              />
              <Tab
                label="Doc. Adjuntos"
                value={TabsValues.docAdjuntos}
                disabled={disabledTabs}
              />
              <Tab
                label="Valorización"
                value={TabsValues.valorizacion}
                disabled={disabledTabs}
              />
              <Tab
                label="Bonificación"
                value={TabsValues.bonificacion2}
                disabled={disabledTabs}
              />
              <Tab
                label="Historial CM"
                value={TabsValues.historialCM}
                disabled={disabledTabs || disabledByDefault}
              />
              <Tab
                label="Restricción DPS"
                value={TabsValues.restriccionDPS}
                disabled={disabledTabs || disabledByDefault}
              />
            </Tabs>
          </Box>
        </Box>
      </Box>
      <Box>
        <TabPanel value={TabsValues.antecedentesGenerales}>
          {loading || (dataCM.idCuentaMedica && !dataCM.estadoCuentaMedica.estado) ? (
            <LoadIngresoCuenta />
          ) : (
            <TabcuentaMedica
              dataCM={dataCM}
              setDataCM={setDataCM}
              createMsg={createMsg}
              setCargaCuenta={setCargaCuenta}
              isDataSaved={isDataSaved}
              setIsDataSaved={setIsDataSaved}
              readOnly={readOnly || !canEditAG}
              setIncompleteInfo={setOpenNeededInfoModal}
              openIncompleteInfo={openNeededInfoModal}
              refreshCuentaMedica={getCuentaMedica}
            />
          )}
        </TabPanel>
        <TabPanel value={TabsValues.eventoHospitalario} sx={{ pr: '20px', pl: '2px' }}>
          <EventoHospitalario
            dataCM={dataCM}
            updateHO={updateHO}
            loadingCuenta={loading}
            readOnly={readOnly || !(canEditEH) || !dataCM.fechaHospitalizacion.fechaInicio}
            setIncompleteInfo={setOpenNeededInfoModal}
            openIncompleteInfo={openNeededInfoModal}
          />
        </TabPanel>
        <TabPanel value={TabsValues.docAdjuntos} sx={{ pr: '20px', pl: '2px' }}>
          <LoadFiles
            dataCM={dataCM}
            readOnly={readOnly || !canEditDA}
          />
        </TabPanel>
        <TabPanel value={TabsValues.valorizacion} sx={{ pr: '20px', pl: '2px' }}>
          <ValorizationProvider>
            <Valorizacion
              dataCM={dataCM}
              readOnly={readOnly || !canEditVal}
            />
          </ValorizationProvider>
        </TabPanel>
        <TabPanel value={TabsValues.bonificacion2} sx={{ pr: '20px', pl: '2px' }}>
          <ValorizationProvider>
            <Valorizacion
              dataCM={dataCM}
              readOnly={readOnly || !canEditBonf}
              isBonif
            />
          </ValorizationProvider>
        </TabPanel>
      </Box>
    </TabContext>
  );
}

export default AdministracionCM;
