import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import { SlimValueSelect2 } from "../../../../components/Selects/valueSelect";
import { boxCard, formHeader } from "../../../../assets/styles/Box/boxs";
import { formTextHeader, inputForm } from "../../../../assets/styles/Text/text";
import OtherIcon from "../../../../assets/icons/otherIconForm.svg";
import { GreenRadio } from "../../../../components/radioButton/CustomRadio";


const DataFechaCobro = ({ dataCM, setDataCM, readOnly }: any) => {

  const onChange = (e: { target: { id: any; value: any; }; }) => {
    const id: string = e.target.id;
    let value = e.target.value;
    if (!value) {
      value = undefined
    }
    setDataCM({ ...dataCM, [id]: value });
  }

  const tipoCuenta = [{ label: "Abierta", value: 1 }, { label: "Paquete", value: 2 }, { label: "Paquete + Adicionales", value: 3 }];

  return (
    <Box sx={boxCard} width={"89.5%"} ml={"4.5%"} mb={"24px"} >
      <Box sx={formHeader}>
        <Typography sx={formTextHeader}>
          <img src={OtherIcon} alt="" style={{ marginBottom: "-3px" }} /> {' '}Otros
        </Typography>
      </Box>
      <Grid mb="32px" container p="24px">
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            Tipo de atención
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              defaultValue="H"
              value={
                dataCM.tipoAtencion || null
              }
              id="tipoAtencion"
              data-testid="tipoAtencion"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "tipoAtencion" } })}
            >
              <FormControlLabel
                value="A"
                control={<GreenRadio data-testid="tipoAtencionAmbulatorio" />}
                label="Ambulatorio"
                id="tipoAtencion"
              />
              <FormControlLabel
                value="H"
                control={<GreenRadio />}
                label="Hospitalario"
                id="tipoAtencion"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            Ley de urgencia
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography><br></br>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              value={
                dataCM.leyUrgencia || null
              }
              id="leyUrgencia"
              data-testid="leyUrgencia"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "leyUrgencia" } })}
            >
              <FormControlLabel value="S" control={<GreenRadio data-testid="leyUrgenciaS" />} label="Si" />
              <FormControlLabel value="N" control={<GreenRadio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} pr="24px">
          <Typography sx={inputForm}  >
            Cuenta Complementaria
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography><br></br>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              defaultValue={false}
              value={dataCM.cuentaComplementaria || null}
              id="cuentaComplementaria"
              data-testid="cuentaComplementaria"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "cuentaComplementaria" } })}
            >
              <FormControlLabel value={"S"} control={<GreenRadio data-testid="cuentaComplementariaS" />} label="Si" />
              <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3}  >
          <Typography sx={inputForm}  >
            SOAP
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography><br></br>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              value={dataCM.soap || null}
              name="soap"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "soap" } })}
            >
              <FormControlLabel value="true" control={<GreenRadio data-testid="soapS" />} label="Si" />
              <FormControlLabel value="false" control={<GreenRadio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px">
          <Typography sx={inputForm}  >
            Preexistencia Declarada
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography><br></br>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              value={
                dataCM.declaracionPreexistencia || null
              }
              name="declaracionPreexistencia"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "declaracionPreexistencia" } })}
            >
              <FormControlLabel value={"S"} control={<GreenRadio data-testid="declaracionPreexistenciaS" />} label="Si" />
              <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px">
          <Typography sx={inputForm}  >
            Cobertura Restringida
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography><br></br>
          <FormControl disabled={!dataCM.vigenciaPoliza || readOnly}>
            <RadioGroup
              row
              value={
                dataCM.restriccionCobertura || null
              }
              id="restriccionCobertura"
              onChange={(e) => onChange({ target: { value: e.target.value, id: "restriccionCobertura" } })}
            >
              <FormControlLabel value={"S"} control={<GreenRadio data-testid="restriccionCoberturaS" />} label="Si" />
              <FormControlLabel value={"N"} control={<GreenRadio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={3} pt="24px" pr="24px" pb="24px">
          <Typography sx={inputForm}  >
            Tipo de cuenta
          </Typography>
          <Typography display={'inline'} color={'#E53935 !important'}>
            *
          </Typography>
          <SlimValueSelect2
            onChange={(e: any) => {
              setDataCM({ ...dataCM, tipoCuentaMedica: e.target.value });
            }}
            disabled={!dataCM.vigenciaPoliza || readOnly}
            value={dataCM.tipoCuentaMedica}
            data={tipoCuenta}
            titulo={"Seleccione un Tipo Cuenta"}
            name="tipoCuentaMedica"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DataFechaCobro;
