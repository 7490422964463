import { useState } from "react";
import { useQuery } from "@apollo/client";
import { obtenerTiposAtencion } from "../../services/administracionCM/administracionCMServices";
import { CustomSelect2 } from "../Selects/customSelect";

type tipoAtencion = {
    id: number,
    valor?: string,
    nombre?: string,
    label?: string,
    value?: string
}

type props = {
    disabled?: boolean,
    onChange: Function,
    value?: string
}
export const SelectTipoAtencion = ({ disabled, onChange, value }: props) => {
    const [tiposAtencion, setTiposAtencion] = useState<tipoAtencion[]>([{ id: 0, label: "Cargando...", value: "-99" }]);
    useQuery(obtenerTiposAtencion, {
        fetchPolicy: "cache-first",
        onCompleted: (res) => {
            const temp: tipoAtencion[] = [];
            if (res.obtenerTiposAtencion) {
                res.obtenerTiposAtencion.forEach((tipo: tipoAtencion) => {
                    temp.push({ id: tipo.id, label: tipo.nombre, value: tipo.valor });
                })
            }
            setTiposAtencion(temp);
        },
        onError: (err) => {
            console.log(err)
            setTiposAtencion([{ id: 0, label: "Error...", value: "-99" }]);
        }
    });
    return (
        <CustomSelect2
            onChange={onChange}
            disabled={disabled}
            value={value || "-99"}
            data={tiposAtencion}
            placeholder={"Seleccione"}
            phvalue={"-99"}
            name="tipoAtencion"
            id="tipoAtencion"
            inputTest="tipoAtencion"
        />
    )
}