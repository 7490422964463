import { Box, Typography } from "@mui/material";
import {
    liquidatedStatusStyle,
    receivedStatusStyle,
    inProcessStatusStyle,
    rejectedStatusStyle,
    returnStatusStyle,
    issuedStatusStyle,
    issuedManualStatusStyle,
    ObsoleteStatusStyle,
    defaultStatusStyle
} from "../../../assets/styles/Box/statusHistorialResTable";
interface Props {
    estado: string | null;
}

const nuevosEstados: { [key: number]: string } = {
    40: "Entregada",
    41: "Recepcionada",
    42: "Visación",
    43: "Valorización",
    44: "Bonificación",
    45: "Liquidada",
    46: "Emitida",
    47: "Anulada",
    48: "Rechazada",
    49: "Devuelta",
    50: "Emitida Manual",
    10: "No Vigente",
    52: "Pendiente de Autorización",
    11: ""
}

export const EstadosHistorial = ({ estado }: Props) => {
    let styleButtonState = {};
    const estadoInt =
        (estado !== null && estado !== undefined) ? parseInt(estado) : 4;
    switch (estadoInt) {
        case 40:
            styleButtonState = receivedStatusStyle;
            break;
        case 41:
            styleButtonState = receivedStatusStyle;
            break;
        case 42:
            styleButtonState = inProcessStatusStyle;
            break;
        case 43:
            styleButtonState = inProcessStatusStyle;
            break;
        case 44:
            styleButtonState = inProcessStatusStyle;
            break;
        case 49:
            styleButtonState = returnStatusStyle;
            break;
        case 48:
            styleButtonState = rejectedStatusStyle;
            break;
        case 45:
            styleButtonState = liquidatedStatusStyle;
            break;
        case 46:
            styleButtonState = issuedStatusStyle;
            break;
        case 47:
            styleButtonState = rejectedStatusStyle;
            break;
        case 50:
            styleButtonState = issuedManualStatusStyle;
            break;
        case 10:
            styleButtonState = ObsoleteStatusStyle;
            break;
        case 11:
            styleButtonState = defaultStatusStyle;
            break;
        case 52:
            styleButtonState = inProcessStatusStyle;
            break;
        default:
            styleButtonState = receivedStatusStyle;
            break;
    }
    return (
        <Box sx={{...styleButtonState, height: [50, 52].includes(estadoInt) ? '44px!important' : '30px!important'}}>
            <Typography variant="h6" fontSize="12px">
                {nuevosEstados[estadoInt]}
            </Typography>
        </Box>
    );
};
