import { isBoolean } from "lodash";
import { prioridadCoberturaType, tipoCoberturas, dataCobertura } from "../tabCuentaMedica/dataCobertura/dataCoberturaEsencial";

export const parseData = (dataCM: any) => {
    let parsedData = JSON.parse(JSON.stringify(dataCM));
    let prioridadCoberturas: dataCobertura = { GES: [], CAEC: [], PLAN: [] };
    let cobertureValues: string[] = [];
    if (parsedData.cuentaComplementaria) {
        if (parsedData.cuentaComplementaria === "true") {
            parsedData.cuentaComplementaria = "S";
        } else {
            parsedData.cuentaComplementaria = "N";
        }
    }
    if (parsedData.datosBeneficiario && parsedData.datosBeneficiario.diagnosticoPrimario && parsedData.datosBeneficiario.diagnosticoPrimario.codigo) {
        let codigo = parsedData.datosBeneficiario.diagnosticoPrimario.codigo.trim();
        if (codigo) {
            parsedData.datosBeneficiario.diagnosticoPrimario.codigo = codigo;
        } else {
            parsedData.datosBeneficiario.diagnosticoPrimario = null;
        }
    }
    if (parsedData.datosBeneficiario && parsedData.datosBeneficiario.diagnosticoSecundario && parsedData.datosBeneficiario.diagnosticoSecundario.codigo) {
        let codigo = parsedData.datosBeneficiario.diagnosticoSecundario.codigo.trim();
        if (codigo) {
            parsedData.datosBeneficiario.diagnosticoSecundario.codigo = codigo;
        } else {
            parsedData.datosBeneficiario.diagnosticoSecundario = null;
        }
    }
    if (isBoolean(parsedData.declaracionPreexistencia)) {
        if (parsedData.declaracionPreexistencia) {
            parsedData.declaracionPreexistencia = "S";
        } else {
            parsedData.declaracionPreexistencia = "N";
        }
    }
    if (parsedData.fechaCobro.fechaInicio) {
        parsedData.fechaCobro.fechaInicio = new Date(parsedData.fechaCobro.fechaInicio)
    }
    if (parsedData.fechaCobro.fechaTermino) {
        parsedData.fechaCobro.fechaTermino = new Date(parsedData.fechaCobro.fechaTermino)
    }
    if (parsedData.fechaHospitalizacion.fechaInicio) {
        parsedData.fechaHospitalizacion.fechaInicio = new Date(parsedData.fechaHospitalizacion.fechaInicio + " " + parsedData.fechaHospitalizacion.horaInicio.replace(' ', ''));
    }
    if (parsedData.fechaHospitalizacion.fechaEgreso) {
        parsedData.fechaHospitalizacion.fechaEgreso = new Date(parsedData.fechaHospitalizacion.fechaEgreso + " " + parsedData.fechaHospitalizacion.horaEgreso.replace(' ', ''));
    }
    if (parsedData.fechaRecepcion) {
        parsedData.fechaRecepcion = new Date(parsedData.fechaRecepcion)
    }
    if (parsedData.fechaProbableLiquidacion) {
        const [year, month, day] = dataCM.fechaProbableLiquidacion.split("/");
        parsedData.fechaProbableLiquidacion = `${day}-${month}-${year}`;
    }
    if (parsedData.graduacionParto) {
        parsedData.graduacionParto = new Date(parsedData.graduacionParto)
    }

    if (isBoolean(parsedData.restriccionCobertura)) {
        if (parsedData.restriccionCobertura) {
            parsedData.restriccionCobertura = "S";
        } else {
            parsedData.restriccionCobertura = "N";
        }
    }

    if (dataCM.dataPrioridad && dataCM.dataPrioridad.length > 0) {
        let data: prioridadCoberturaType[] = dataCM.dataPrioridad;
        data.forEach(val => {
            if (!cobertureValues.includes(tipoCoberturas[val.cobertura - 1])) {
                cobertureValues.push(tipoCoberturas[val.cobertura - 1])
            }
            if (val.casoGES && !prioridadCoberturas.GES.includes(val.casoGES)) {
                prioridadCoberturas.GES.push(val.casoGES)
            }
            if (val.casoCAEC && !prioridadCoberturas.CAEC.includes(val.casoCAEC)) {
                prioridadCoberturas.CAEC.push(val.casoCAEC)
            }
            if (!val.casoGES && !val.casoCAEC) {
                prioridadCoberturas.PLAN.push(val.id!)
            }
        });
    }

    return { ...parsedData, datosCoberturas: { prioridadCoberturas, cobertureValues } };
}