import { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton, AlertColor, Divider } from "@mui/material";
import { boxCardStyleCMWhitoutPnh } from "../../../assets/styles/Box/boxs";
import { SecondaryButton } from "../../../assets/styles/Button/Button";
import { CustomInput } from "../../../assets/styles/Input/input";
import SearchIcon from "../../../assets/icons/searchInput.svg";
import Close from "../../../assets/icons/closeFiltro.svg";
import {
  TextLine,
  boldTitleBox,
  formTextHeader,
} from "../../../assets/styles/Text/text";
import { LoadValorizacionMain } from "./components/loaderValorizacion";
import { ModalNuevoDocumento } from "./components/modalNuevoDoc";
import { NetworkStatus, useQuery } from "@apollo/client";
import { consultaPrestaciones } from "../../../services/administracionCM/valorizacionService";
import { DocumentoCobro } from "./components/documentoCobro";
import SnackBar, { stateProps } from "../../../components/Snackbar/Snackbar";
import { AccionModulo } from "./components/btnAction";
import { ModalEliminarPrestaciones } from "./components/modalEliminarPrestaciones";
import { ModalAddCanasta } from "./components/modalAddCanasta";
import { ButtonEdit } from "./components/buttonEdit";
import { useValorizationContext } from "../../../context/valorizationContext";
import { parseMiles } from "../../../utils/monedaUtils";
import BotonCarga from "../../../components/Buttons/LoadingButton";
import { Stack } from "@mui/system";
import { SmallSwitch } from "../../../components/Switch/SmallSwitch";
import { BtnSolicitarAutorizacion } from "./components/btnSolicitarAutorizacion";
import { NuevosEstadosCuentaMedica } from "../../../utils/enums";
import { AutorizarCuenta } from "./components/btnAutorizar";
import useGenerarPdf from "../../emisionCuentaMedica/hook/useGenerarPdf";
import { reloaderTableValo } from "../utils/reloader";

const estadoCero = {
  montoPrestacion: 0,
  montoBonificado: 0,
  montoCopago: 0,
  documentosCobro: [],
};

export const Valorizacion = ({ dataCM, readOnly, isBonif }: any) => {
  const isCuentaAuth = dataCM.estadoCuentaMedica.estado === NuevosEstadosCuentaMedica.PendienteAutorizacion && !readOnly;
  const disablePreLiq = dataCM.estadoCuentaMedica.estado === NuevosEstadosCuentaMedica.PendienteAutorizacion;
  const [valorizacionData, setValorizacionData] = useState<any>(estadoCero);
  const [documentosCobro, setDocumentosCobro] = useState<any>([]);
  const [presFilter, setPresFilter] = useState("");
  const [presData, setPresData] = useState("");
  const [documentsID, setDocumentsID] = useState<number[]>([]);
  const [selectedDocumentsID, setSelectedDocumentsID] = useState<number[]>([]);
  const [selectedPrest, setSelectedPrest] = useState<number[]>([]);
  const [openModalNuevoDoc, setOpenModalNuevoDoc] = useState(false);
  const [openModalDeleteDetails, setOpenModalDeleteDetails] =
    useState<boolean>(false);
  const [authFilter, setAuthFilter] = useState<boolean>(isCuentaAuth);
  const [openAsignaCanasta, setOpenAsignaCanasta] = useState<boolean>(false);
  const [lockDocs, setLockDocs] = useState<boolean>(false);
  const [requiresAuth, setRequiresAuth] = useState<boolean>(false);
  const [snackData, setSnackData] = useState<stateProps>({
    abrir: false,
    mensaje: "",
    severidad: "success",
  });
  const { selectedDetailItem, setSelectedDetailItem, setSelectedDocTypes } =
    useValorizationContext();
  const { idCuentaMedica } = dataCM;

  const { loading, refetch, networkStatus, startPolling, stopPolling } =
    useQuery(consultaPrestaciones, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {
        idCuentaMedica: dataCM.idCuentaMedica,
        prestacion: presFilter,
      },
      onCompleted: (data) => {
        setValorizacionData(data.consultaDetalleCobro);
        if (data.consultaDetalleCobro.documentosCobro.length > 0) {
          const filtrado = data.consultaDetalleCobro.documentosCobro.filter(
            (doc: any) =>
              doc.estadoValorizacion !== 52 &&
              doc.detallePrestaciones.contador > 0 &&
              doc.folioDocumento &&
              doc.valorizarHabilitado &&
              (([1, 4].includes(doc.cobertura.tipoCobertura) &&
                doc.cobertura.folioCobertura) ||
                [2, 3].includes(doc.cobertura.tipoCobertura))
          );
          const idDocs = filtrado.map((doc: any) => doc.id);
          const auths = data.consultaDetalleCobro.documentosCobro.filter((docs: any) => docs.requiresAuth);
          setRequiresAuth(auths.length > 0);
          setDocumentsID(idDocs);
          setDocumentosCobro(data.consultaDetalleCobro.documentosCobro);
          setSelectedDocumentsID(
            selectedDocumentsID.filter((id) => idDocs.includes(id))
          );
          reloaderTableValo();
        } else {
          setDocumentosCobro([]);
          setSelectedDocumentsID([]);
        }
      },
      onError: (err) => {
        console.log(err.message);
        setValorizacionData(estadoCero);
        setDocumentsID([]);
        setSelectedDocumentsID([]);
        setDocumentosCobro([]);
      },
    });

  const cleanSearch = () => {
    setPresFilter("");
    setPresData("");
    setValorizacionData(estadoCero);
  };

  const muestraMensaje = (mensaje: string, severidad: AlertColor) => {
    setSnackData({
      abrir: true,
      mensaje: mensaje,
      severidad: severidad,
    });
  };

  const { handleOnClickGenerarPdf, loadingGenerarPDF } =
    useGenerarPdf(muestraMensaje);

  const isAllBonifDone =
    documentosCobro.length && documentosCobro.every((doc: any) =>
      doc.estadoBonificacion === 53 || doc.estadoBonificacion === 55
        ? true
        : false
    );

  useEffect(() => {
    startPolling(30000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const cleanSelected = () => {
    setSelectedDetailItem([]);
    setSelectedDocTypes({
      GES: 0,
      CAEC: 0,
      PLAN: 0,
      "GES-CAEC": 0,
    });
  };
  return (
    <>
      <ModalEliminarPrestaciones
        isOpen={openModalDeleteDetails}
        detailsIds={selectedDetailItem}
        onClose={() => {
          setOpenModalDeleteDetails(!openModalDeleteDetails);
          cleanSelected();
        }}
        muestraMensaje={muestraMensaje}
        refetch={refetch}
      />
      <ModalAddCanasta
        onOpen={openAsignaCanasta}
        onClose={() => setOpenAsignaCanasta(false)}
        prestaciones={selectedDetailItem}
        clearPrestaciones={() => setSelectedDetailItem([])}
        refetch={refetch}
        muestraMensaje={muestraMensaje}
      />
      <SnackBar state={snackData} setState={setSnackData} />
      {selectedDetailItem.length > 0 && (
        <ButtonEdit
          handleBulkDelete={() => setOpenModalDeleteDetails(true)}
          handleBulkBasket={() => setOpenAsignaCanasta(true)}
          isLoading={false}
        />
      )}
      {openModalNuevoDoc ? (
        <ModalNuevoDocumento
          dataCM={dataCM}
          accion="crear"
          onOpen={openModalNuevoDoc}
          onClose={() => setOpenModalNuevoDoc(false)}
          muestraMensaje={muestraMensaje}
          refetch={refetch}
        />
      ) : null}
      <Box sx={boxCardStyleCMWhitoutPnh}>
        {loading &&
          !(
            networkStatus === NetworkStatus.poll ||
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.ready
          ) ? (
          <LoadValorizacionMain />
        ) : (
          <Grid container p="16px" justifyContent={'space-evenly'}>
            <Grid item xs={12} direction={'row'}>
              <Stack direction={'row'} mb='10px'>
                <Typography pb='8px' variant="h5">
                  Detalle de Prestaciones
                </Typography>
                <Divider hidden={!isBonif} orientation="vertical" sx={{ pt: '20px', pr: '20px', pb: '20px', mr: '10px' }} />
                <Typography hidden={!isBonif} mr='10px' pt="8px" variant="h6" >Ver Pte. Autorización</Typography>
                {isBonif ? <SmallSwitch sx={{ mt: '10px' }} color='primary' checked={authFilter} onChange={() => setAuthFilter(!authFilter)} /> : null}
              </Stack>
            </Grid>
            <Grid item xs={4.5} mt={"10px"}>
              <Stack direction={'row'}>
                <CustomInput
                  fullWidth
                  placeholder="Ingresa un código de prestación"
                  sx={{ height: "48px" }}
                  InputProps={{
                    endAdornment: (
                      <IconButton disabled={!presData} onClick={cleanSearch}>
                        <img
                          src={!presData ? SearchIcon : Close}
                          alt="limpiar rut"
                        />
                      </IconButton>
                    ),
                  }}
                  value={presData}
                  onChange={(e: { target: { value: any } }) =>
                    setPresData(e.target.value || "")
                  }
                />
                <SecondaryButton
                  sx={{ p: "15px 48px", height: "48px", ml: "15px" }}
                  onClick={() => {
                    setPresFilter(presData);
                    setValorizacionData(estadoCero);
                  }}
                  disabled={!presData}
                >
                  Buscar
                </SecondaryButton>
              </Stack>
            </Grid>
            <Grid item xs={7.5} textAlign={"right"} justifyContent={"flex-end"} sx={{ minWidth: "490px" }} right={"0px"} mt={"10px"}>
              <Box pr="16px" display={"inline"}>
                {isBonif ? (
                  <BotonCarga
                    sx={{ p: "15px 16px", minWidth: "168px" }}
                    type="secondary"
                    toDo={() => handleOnClickGenerarPdf({ idCuentaMedica, tipoDocumento: "FORMULARIO LIQUIDACION (BORRADOR)" })}
                    label={"Ver preliquidación"}
                    loading={loadingGenerarPDF}
                    disabled={disablePreLiq || loadingGenerarPDF || !isAllBonifDone}
                  />
                ) : (
                  <SecondaryButton
                    onClick={() => setOpenModalNuevoDoc(true)}
                    sx={{ p: "15px 16px" }}
                    disabled={readOnly}
                  >
                    Nuevo documento
                  </SecondaryButton>
                )}
                {isBonif ?
                  isCuentaAuth ?
                    <AutorizarCuenta
                      id={dataCM.idCuentaMedica}
                      disabled={readOnly}
                      showMessage={muestraMensaje}
                      refetch={refetch}
                    />
                    :
                    <BtnSolicitarAutorizacion
                      requiresAuth={requiresAuth}
                      idCuentaMedica={dataCM.idCuentaMedica}
                      disabled={readOnly}
                      showMessage={muestraMensaje}
                      refetch={refetch}
                    />
                  : null}
              </Box>
              <AccionModulo
                isBonif={isBonif}
                idDocumentos={
                  selectedDocumentsID.length > 0
                    ? selectedDocumentsID
                    : documentsID
                }
                valorizacionData={valorizacionData.documentosCobro}
                idCuentaMedica={dataCM.idCuentaMedica}
                clean={cleanSelected}
                setLockDocs={setLockDocs}
                muestraMensaje={muestraMensaje}
                refetch={refetch}
                isDisabled={readOnly || isCuentaAuth || requiresAuth}
              />
            </Grid>
            {documentosCobro.length > 0 ? (
              <>
                <Grid item xs={12} pt={"32px"}>
                  <Box
                    border={"2px solid #EEECFB"}
                    borderRadius={"8px"}
                    p="16px"
                  >
                    <Grid container>
                      <Grid item xs={3} pr="16px">
                        <Box className="purpleBox">
                          <Typography sx={TextLine} display={"inline"}>
                            Total Prestación:{" "}
                          </Typography>
                          <Typography sx={formTextHeader} display={"inline"}>
                            {parseMiles(valorizacionData.montoPrestacion || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} pr="16px">
                        <Box className="purpleBox">
                          <Typography sx={TextLine} display={"inline"}>
                            Total Convenido:{" "}
                          </Typography>
                          <Typography sx={formTextHeader} display={"inline"}>
                            {parseMiles(
                              valorizacionData.montoPrestacionConvenio || 0
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} pr="16px">
                        <Box className="purpleBox">
                          <Typography sx={TextLine} display={"inline"}>
                            Dif. Total Convenido:{" "}
                          </Typography>
                          <Typography sx={formTextHeader} display={"inline"}>
                            {parseMiles(valorizacionData.diferencia || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box className="purpleBox">
                          <Typography sx={TextLine} display={"inline"}>
                            Porcentaje Total Diferencia:{" "}
                          </Typography>
                          <Typography sx={formTextHeader} display={"inline"}>
                            {valorizacionData.diferenciaPercent}%
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} pt={"32px"}>
                  {documentosCobro.map((docCobro: any) => (
                    <DocumentoCobro
                      key={docCobro.id}
                      docuCobro={docCobro}
                      prestacion={presFilter}
                      muestraMensaje={muestraMensaje}
                      dataCM={dataCM}
                      refetch={refetch}
                      disabled={readOnly || lockDocs}
                      isBonif={isBonif}
                      checkValue={selectedDocumentsID}
                      setCheck={setSelectedDocumentsID}
                      checkPrest={selectedPrest}
                      setCheckPrest={setSelectedPrest}
                      isCuentaAuth={isCuentaAuth}
                      authFilter={authFilter}
                    />
                  ))}
                </Grid>
              </>
            ) : null}
            {documentosCobro.length === 0 && !presFilter ? (
              <Grid item xs={12} pt={"32px"}>
                <Box p="32px" alignItems={"center"} textAlign={"center"}>
                  <Typography sx={boldTitleBox}>
                    Valorización sin documentos
                  </Typography>
                  <Typography pt="8px" pb="16px">
                    Haz clic en “Nuevo documento” para crear uno nuevo
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Box>
    </>
  );
};
