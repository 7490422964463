import { Fragment, useState } from "react"
import { SecondaryButton } from "../../../../components/Buttons/Button"
import { WarningModalCustom } from "../../../../components/Modal/warningModalCustom"
import { Grid, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { autorizaCuenta } from "../../../../services/administracionCM/valorizacionService";
import { reloaderCuenta, reloaderTableValo } from "../../utils/reloader";

export const AutorizarCuenta = ({ id, showMessage, disabled, refetch }: { id: number, showMessage: Function, disabled?: boolean, refetch: Function }) => {
    const [modalAuth, setModalAuth] = useState<boolean>(false);
    const [autorizar, { loading }] = useMutation(autorizaCuenta, {
        onCompleted: (res) => {
            if (res.autorizarPrestaciones.estado !== 0) {
                showMessage(res.autorizarPrestaciones.mensaje, 'error');
            } else {
                showMessage('La bonificación forzada ha sido aplicada exitosamente', 'success');
                setModalAuth(false);
                reloaderCuenta();
                reloaderTableValo();
                refetch();
            }
        },
        onError: (err) => {
            console.log(err.message);
            showMessage('Ocurrió un error al autorizar las prestaciones, intente nuevamente', 'error');
        }
    });

    const autorizarCuenta = () => {
        autorizar({
            variables: {
                idCuentaMedica: id
            }
        })
    }

    return (
        <Fragment>
            <WarningModalCustom
                onClose={() => setModalAuth(false)}
                title="Autorizar"
                contentText={
                    <Grid>
                        <Typography>
                            Al autorizar la solicitud se forzaran las prestaciones<br />
                            marcadas como Pdte. de autorización por un monto<br />
                            menor al mínimo obligatorio.
                        </Typography>
                    </Grid>
                }
                primaryButton="Autorizar"
                secondaryButton='Cancelar'
                onConfirm={autorizarCuenta}
                loading={loading}
                onOpen={modalAuth}
                isSuccess
            />
            <SecondaryButton
                sx={{ ml: "15px", width: "150px" }}
                disabled={disabled}
                data-testid="authBTN"
                onClick={() => setModalAuth(true)}
            >
                Autorizar
            </SecondaryButton>
        </Fragment>
    )
}