import { gql } from "@apollo/client";
import {PRESTACION_FRAGMENT} from "../fragments"

export const mutationUploadPrestaciones = gql`
mutation mutationUploadPrestaciones($idCuentaMedica: Int, $prestacionesInput: ArchivoInput)
{
  cargarPrestaciones(idCuentaMedica: $idCuentaMedica, prestacionesInput: $prestacionesInput) 
  {
    estado, mensaje
  }
}`;


export const mutationAgregarBonificacion = gql`
mutation mutationAgregarBonificacion($BonificacionInput: BonificacionInput){
  crearBonificacion(BonificacionInput: $BonificacionInput) {
    estado
    mensaje
  }
}`;

export const mutationEliminarBonificacion = gql`
mutation eliminarPrestacion($idPrestacion: Int ){
  eliminarPrestacion(idPrestacion : $idPrestacion) {
    estado
    mensaje
  }
}`;

export const mutationEditarBonificacion = gql`
mutation mutationEditarBonificacion($id : Int , $BonificacionInput: BonificacionInput ){
  editarBonificacion( id : $id , BonificacionInput: $BonificacionInput ){
    estado
    mensaje
  }
}`;

export const mutationForzarBonificacion = gql`
mutation forzarBonificacion($id: Int , $montoBonificacionForzada : Int, $requireAuth: Boolean ){
  forzarBonificacion(id : $id , montoBonificacionForzada : $montoBonificacionForzada, requireAuth: $requireAuth) {
    respuesta {
      estado
      mensaje
    }
  }
}`;

export const bonificar = gql`
mutation bonificar($idCuentaMedica: Int, $bonificarTodo: Boolean){
  bonificar(idCuentaMedica: $idCuentaMedica, bonificarTodo: $bonificarTodo){
    estado
    mensaje
  }
}`;

export const mutationModifTipoCober = gql`
${PRESTACION_FRAGMENT}
mutation asignarCoberturas($idCuentaMedica: Int, $idPrestaciones: [Int!]!, $idCobertura: Int){
  asignarCoberturas(idCuentaMedica:$idCuentaMedica, idPrestaciones:$idPrestaciones, idCobertura:$idCobertura){
      respuesta {
        estado
        mensaje
      }
      prestaciones{
        prestacion{
          ...PrestacionFragment
        }
        cobro{
          id
          tipoDocumento
        }
        cobertura{
          id
          tipoCobertura
          folioCobertura
          secuencia
        }
      }
  }
}`;

export const queryObtenerPrestaciones = gql`
${PRESTACION_FRAGMENT}
query queryObtenerPrestaciones($id: Int){
  obtenerPrestaciones(idCuentaMedica: $id){
    prestacion{
      ...PrestacionFragment
    }
    cobro{
      id
      tipoDocumento
    }
    cobertura{
      id
      tipoCobertura
      folioCobertura
      secuencia
    }
  }
}`;

export const mutationRestablecerBonificacion = gql`
${PRESTACION_FRAGMENT}
  mutation mutationRestablecerBonificacion($id: Int){
    restablecerBonificacion(id: $id){
      respuesta{
        estado
        mensaje
      }
      prestacionDetalle{
        prestacion{
          ...PrestacionFragment
        }
        cobro{
          id
          tipoDocumento
        }
        cobertura{
          id
          tipoCobertura
          folioCobertura
          secuencia
        }
      }
    }
  }`;

export const mutationGenerateDocument = gql`
query($idCuentaMedica:Int, $tipoDocumento : String){
  generarPdf(idCuentaMedica: $idCuentaMedica, tipoDocumento:$tipoDocumento){
    estado
    codigo
    message
    data{
      documentoInfo{
        nombreDocumento
      }
      documento
    }
  }
}`;

export const mutationActualizarBeneficioComplementario = gql`
${PRESTACION_FRAGMENT}
mutation ($idPrestacion: Int, $bc: Boolean){
  actualizarBeneficioComplementario(idPrestacion: $idPrestacion, bc: $bc){
    respuesta {
      estado
      mensaje
    }

    prestacionDetalle{
      prestacion{
        ...PrestacionFragment
      }
      cobro{
        id
        tipoDocumento
      }
      cobertura{
        id
        tipoCobertura
        folioCobertura
        secuencia
      }
    }
  }
}`;

export const queryConsultarBonificacion = gql`
query ($idCuentaMedica: Int){
  consultarBonificacion(idCuentaMedica: $idCuentaMedica){
    prestacionesProcesadas
    prestacionesEnProceso
    totalPrestaciones
    porcentaje
  }
}`;