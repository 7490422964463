import { gql } from "@apollo/client";

export const consultaPrestaciones = gql`
query ($idCuentaMedica: Int, $prestacion: String) {
    consultaDetalleCobro(
      idCuentaMedica: $idCuentaMedica,
      prestacion: $prestacion
    ) {
      montoPrestacion
      montoPrestacionConvenio
      diferencia
      diferenciaPercent
      documentosCobro{
          id
          idCuentaMedica
          folioDocumento
          fechaDocumento
          tipoDocumento
          monto
          codigoLugarAtencion
          rutPrestador
          razonSocial
          lugarAtencion
          codigoLugarAtencionIsapre
          estadoValorizacion
          estadoBonificacion
          valorizarHabilitado
          requiresAuth
          totalPrestaciones
          cobertura{
              id
              documentoCobro
              tipoCobertura
              secuencia
              prestacionPrincipalIsapre{
                  codigo
                  descripcion
              }
              prestacionPrincipalPrestador{
                  codigo
                  descripcion
              }
              diagnostico{
                  codigo
                  descripcion
              }
              folioCobertura
          }
          detallePrestaciones{
            contador
          }
      }
    }
  }
`;

export const consultaDetallePrestaciones = gql`
    query ($idCuenta: Int,
        $idCobro: Int,
        $idCobertura: Int,
        $prestacion: String,
        $limit: Int,
        $offset: Int,
        $estadoAutorizacion: [Int]) {
    obtenerDetallePrestacion(
        idCuenta: $idCuenta
        idCobro: $idCobro
        idCobertura: $idCobertura
        prestacion: $prestacion
        limit: $limit
        offset: $offset
        estadoAutorizacion: $estadoAutorizacion
    ) {
        contador
        prestaciones{
            id
            codigoPrestacionPrestador
            glosaPrestacionPrestador
            rutPrestador
            idTipoProfesional
            idItemIsapre
            codigoPrestacionIsapre
            glosaPrestacionIsapre
            montoPrestacionCobro
            frecuenciaAtencion
            montoPrestacionDefinitivo
            montoPrestacionConvenio
            montoPrestacionConvenioTotal
            montoBonificacionMinima
            porcentajeCobro
            beneficioComplementario
            atencionUrgencia
            tipoAtencion
            numeroOrdenIntervencion
            montoBonificadoPlan
            montoBonificacionTopada
            montoBonificacionForzado
            usuarioAutorizacionMontoPrestacionForzada
            montoCopago
            codigoCanasta
            descripcionItemProfesional
            cobertura
            EstadoAutorizacion
            glosaEstadoAutorizacion
            diferenciaForzadoMinimo
            prestacionRecargoHorario
        }
    }
    }
`;

export const valorizarDocumentos = gql`
    mutation($idDocumentos:[Int], $idCuentaMedica: Int){
        valorizarDocumentos(idDocumentos: $idDocumentos, idCuentaMedica:$idCuentaMedica) {
            LogResult
            StatusCode
            FunctionError
        }
    }
`;

export const bonificarDocumentos = gql`
    mutation($idDocs:[Int], $idCuentaMedica: Int){
        enviarBonificacion(idDocs: $idDocs, idCuentaMedica:$idCuentaMedica) {
            LogResult
            StatusCode
            FunctionError
        }
    }
`;

export const asignaCanasta = gql`
    mutation ($prestaciones: [Int], $canasta: String){
        asignarCanastaPrestaciones(prestaciones: $prestaciones, canasta: $canasta){
            estado
            codigo
            mensaje
        }
    }
`;

export const autorizaCuenta = gql`
mutation mutationAutorizarPrestacion($idCuentaMedica: Int) {
    autorizarPrestaciones(idCuentaMedica: $idCuentaMedica) {
      estado
      mensaje
    }
  }`;