import { Table, TableHead, TableRow, TableCell, TableBody, Collapse } from "@mui/material"
import { Fragment } from "react"
import { Canasta, ObtenerCasosGESCAEC } from "./coberturaSpan"
import { verificaLargo } from "../../../../components/Tooltip/Tooltip"

type TableDataCobertureProps = {
    dataCM: any
    isCaec?: any
    casoGES?: string
    canastas?: Canasta[]
    dataCasos?: ObtenerCasosGESCAEC[]
}

export const TableDataCoberture = ({ dataCM, isCaec, casoGES, canastas, dataCasos }: TableDataCobertureProps) => {
    const dataTabla = dataCM.dataPrioridad.filter((item: any) => isCaec ? item.cobertura === 2 : item.casoGES === Number(casoGES));

    return (
        <Collapse in={dataTabla.length > 0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="color-head-table">
                    <TableRow>
                        <TableCell className="headAccordion" width={'15%'}>N°</TableCell>
                        {isCaec ?
                            <Fragment>
                                <TableCell className="headAccordion" width={'15%'}>Código Diagnostico</TableCell>
                                <TableCell className="headAccordion" width={'70%'}>Glosa Diagnostico</TableCell>
                            </Fragment>
                            :
                            <Fragment>
                                <TableCell className="headAccordion" width={'15%'}>Código Canasta</TableCell>
                                <TableCell className="headAccordion" width={'70%'}>Glosa Canasta</TableCell>
                            </Fragment>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTabla.map((row: any, indx: number) => {
                        if (isCaec) {
                            const dataCaec = dataCasos?.find(caso => Number(caso.folio) === row.casoCAEC);

                            return (<TableRow hover key={`${row.idCuenta}${row.cobertura}${row.casoCAEC}`}>
                                <TableCell className="headAccordion">
                                    {indx + 1}
                                </TableCell>
                                <TableCell className="headAccordion">
                                    {row.CIE10}
                                </TableCell>
                                <TableCell className="headAccordion">
                                    {verificaLargo(dataCaec?.glosaCie10 || "", 115)}
                                </TableCell>
                            </TableRow>)
                        } else {
                            const canasta = canastas!.find(can => can.canasta === row.canasta);
                            return (<TableRow hover key={`${row.idCuenta}${row.cobertura}${row.canasta}`}>
                                <TableCell className="headAccordion">
                                        {indx + 1}
                                </TableCell>
                                <TableCell className="headAccordion">
                                        {row.canasta}
                                </TableCell>
                                <TableCell className="headAccordion">
                                        {verificaLargo(canasta!.descripcioncanasta || "", 115)}
                                </TableCell>
                            </TableRow>)
                        }
                    }
                    )}
                </TableBody>
            </Table>
        </Collapse>
    )
}